const defaultconvertTimeTo12HourFormat = (time) => {
  // Create a Date object using the input time string
  const inputTime = new Date(`2000-01-01T${time}`);

  // Convert to 12-hour format with AM/PM
  const formattedTime = inputTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });

  return formattedTime;
};

export default defaultconvertTimeTo12HourFormat;
