// Material Dashboard 2 React example components
import {
  Card,
  FormControlLabel,
  Icon,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MDBox from "components/MDBox";
import { Link } from "react-router-dom";
import colors from "../../assets/theme/base/colors";
import Footer from "../../components/Footer";
import DashboardNavbar from "../../components/Navbars/DashboardNavbar";
import DashboardLayout from "../../layouts/LayoutContainers/DashboardLayout";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import scheduleBG from "assets/images/calendar.jpg";
import APIError from "components/ApiError";
import daysDifferenceCalculator from "components/Formatter/DaysDifferenceCalculator";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import studentAPIService from "services/studentAPI-service";

function Schedule() {
  const [searchName, setSearchName] = useState("");
  const [status, setStatus] = useState("Active");
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [sundayData, setSundayData] = useState([]);
  const [actualSundayData, setActualSundayData] = useState([]);
  const [mondayData, setMondayData] = useState([]);
  const [actualMondayData, setActualMondayData] = useState([]);
  const [tuesdayData, setTuesdayData] = useState([]);
  const [actualTuesdayData, setActualTuesdayData] = useState([]);
  const [wednesdayData, setWednesdayData] = useState([]);
  const [actualWednesdayData, setActualWednesdayData] = useState([]);
  const [thursdayData, setThursdayData] = useState([]);
  const [actualThursdayData, setActualThursdayData] = useState([]);
  const [fridayData, setFridayData] = useState([]);
  const [actualFridayData, setActualFridayData] = useState([]);
  const [saturdayData, setSaturdayData] = useState([]);
  const [actualSaturdayData, setActualSaturdayData] = useState([]);

  useEffect(() => {
    fetchStudentSchedulesData();
  }, [status]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const fetchStudentSchedulesData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const studentSchedulesResponse =
        await studentAPIService.getStudentsScheduleList(status);

      setTotal(studentSchedulesResponse.data.count);
      // If only there is data, filter for each days
      if (studentSchedulesResponse.data.count > 0) {
        setSundayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "0";
          })
        );
        setActualSundayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "0";
          })
        );
        setMondayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "1";
          })
        );
        setActualMondayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "1";
          })
        );
        setTuesdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "2";
          })
        );
        setActualTuesdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "2";
          })
        );
        setWednesdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "3";
          })
        );
        setActualWednesdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "3";
          })
        );
        setThursdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "4";
          })
        );
        setActualThursdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "4";
          })
        );
        setFridayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "5";
          })
        );
        setActualFridayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "5";
          })
        );
        setSaturdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "6";
          })
        );
        setActualSaturdayData(
          studentSchedulesResponse.data.results.filter((day) => {
            return day.day === "6";
          })
        );
      }

      setTimeout(() => {
        setProcessing(false);
      }, 100);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const daysMap = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [filteredDays, setFilteredDays] = useState([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);

  const handleClearAll = () => {
    setFilteredDays([]);
  };

  const handleFilterDay = (event, day) => {
    const selectedIndex = filteredDays.indexOf(day);
    let newFilteredDays = [];

    if (selectedIndex === -1) {
      newFilteredDays = newFilteredDays.concat(filteredDays, day);
    } else if (selectedIndex === 0) {
      newFilteredDays = filteredDays.slice(1);
    } else if (selectedIndex === filteredDays.length - 1) {
      newFilteredDays = filteredDays.slice(0, -1);
    } else if (selectedIndex > 0) {
      newFilteredDays = newFilteredDays.concat(
        filteredDays.slice(0, selectedIndex),
        filteredDays.slice(selectedIndex + 1)
      );
    }

    // Sorting the filtered days
    newFilteredDays = sortDays(newFilteredDays);
    setFilteredDays(newFilteredDays);
  };

  const sortDays = (filteredDays) => {
    for (let i = 0; i < filteredDays.length - 1; i++) {
      for (let j = 0; j < filteredDays.length - 1 - i; j++) {
        if (daysMap[filteredDays[j]] > daysMap[filteredDays[j + 1]]) {
          const temp = filteredDays[j];
          filteredDays[j] = filteredDays[j + 1];
          filteredDays[j + 1] = temp;
        }
      }
    }
    return filteredDays;
  };

  const isDaySelected = (id) => filteredDays.indexOf(id) !== -1;

  const getTotalNumber = (day) => {
    switch (day) {
      case "Sunday":
        return sundayData.length;
      case "Monday":
        return mondayData.length;
      case "Tuesday":
        return tuesdayData.length;
      case "Wednesday":
        return wednesdayData.length;
      case "Thursday":
        return thursdayData.length;
      case "Friday":
        return fridayData.length;
      case "Saturday":
        return saturdayData.length;
    }
  };

  const getDayData = (day) => {
    switch (day) {
      case "Sunday":
        return sundayData;
      case "Monday":
        return mondayData;
      case "Tuesday":
        return tuesdayData;
      case "Wednesday":
        return wednesdayData;
      case "Thursday":
        return thursdayData;
      case "Friday":
        return fridayData;
      case "Saturday":
        return saturdayData;
    }
  };

  const handleNameSearch = (e) => {
    setSearchName(e.target.value);
    // Now filter all the data
    if (e.target.value) {
      // Filter for each day

      let tempSundayData = actualSundayData.filter((data) => {
        if (
          data.student.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setSundayData(tempSundayData);

      let tempMondayData = actualMondayData.filter((data) => {
        if (
          data.student.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setMondayData(tempMondayData);

      let tempTuesdayData = actualTuesdayData.filter((data) => {
        if (
          data.student.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setTuesdayData(tempTuesdayData);

      let tempWednesdayData = actualWednesdayData.filter((data) => {
        if (
          data.student.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setWednesdayData(tempWednesdayData);

      let tempThursdayData = actualThursdayData.filter((data) => {
        if (
          data.student.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setThursdayData(tempThursdayData);

      let tempFridayData = actualFridayData.filter((data) => {
        if (
          data.student.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setFridayData(tempFridayData);

      let tempSaturdayData = actualSaturdayData.filter((data) => {
        if (
          data.student.first_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
      setSaturdayData(tempSaturdayData);
    } else {
      setSundayData(actualSundayData);
      setMondayData(actualMondayData);
      setTuesdayData(actualTuesdayData);
      setWednesdayData(actualWednesdayData);
      setThursdayData(actualThursdayData);
      setFridayData(actualFridayData);
      setSaturdayData(actualSaturdayData);
    }
  };

  const getColor = (date) => {
    const difference = daysDifferenceCalculator(date);
    if (difference < 6) {
      return "success";
    } else if (difference >= 6 && difference < 14) {
      return "warning";
    } else {
      return "error";
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={10} pb={10} overflow="auto">
        <Card>
          {/* Heading */}
          <MDBox
            mx={2}
            mt={-6}
            py={4}
            px={2}
            variant="gradient"
            bgColor="info"
            borderRadius="xxl"
            coloredShadow="info"
            height="100px"
            sx={{
              backgroundImage: ({
                functions: { rgba, linearGradient },
                palette: { gradients },
              }) =>
                `${linearGradient(
                  rgba(gradients.info.main, 0.7),
                  rgba(gradients.info.state, 0.1)
                )}, url(${scheduleBG})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              overflow: "hidden",
            }}
          >
            <MDTypography variant="h4" color="dark">
              Schedule
            </MDTypography>
          </MDBox>

          {/* Days CheckBox */}
          <MDBox
            width="100%"
            display="flex"
            flexWrap="wrap"
            justifyContent="center"
            px={6}
            pt={5}
          >
            {days.map((day) => (
              <FormControlLabel
                key={"checkbox" + day}
                label={`${day.charAt(0)}${day.charAt(1)}`}
                control={
                  <Checkbox
                    checked={isDaySelected(day)}
                    color="primary"
                    size="medium"
                    onClick={(event) => {
                      handleFilterDay(event, day);
                    }}
                  />
                }
              />
            ))}

            <MDButton
              size="small"
              variant="text"
              color="info"
              onClick={handleClearAll}
            >
              Clear All
            </MDButton>
          </MDBox>

          {/* Search By Name */}
          <MDBox p={2} display="flex" justifyContent="space-between" gap={2}>
            <MDInput
              placeholder="Search by first name..."
              size="medium"
              value={searchName}
              sx={{ width: 250, background: "white", borderRadius: "6px" }}
              type="search"
              onChange={handleNameSearch}
              InputProps={{
                startAdornment: (
                  <Icon sx={{ color: "gray" }} fontSize="small">
                    search
                  </Icon>
                ),
              }}
            />
            {/* Status  */}
            <Select
              id="statusSelectLabel"
              value={status}
              displayEmpty
              onChange={handleStatusChange}
              startAdornment={
                <InputAdornment position="start">
                  <Icon color="primary">filter_list</Icon>
                </InputAdornment>
              }
              variant="outlined"
              sx={{
                "& .MuiSelect-select.MuiSelect-select": {
                  color:
                    status === ""
                      ? colors.info.main
                      : status === "Active"
                      ? colors.success.main
                      : status === "Prospective"
                      ? colors.warning.main
                      : status === "Waitlist"
                      ? colors.info.main
                      : colors.error.main,
                  fontWeight: 500,
                  height: "2.8rem",
                },
                background: colors.white.main,
              }}
            >
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Inactive"}>Inactive</MenuItem>
            </Select>
          </MDBox>

          {/* Processing Bar */}
          {processing && (
            <MDBox pt={2} px={2}>
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            </MDBox>
          )}

          {/* Table */}
          {!processing && total > 0 && (
            <MDBox pt={3} px={1} pb={5}>
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table>
                  {/* Header Columns */}
                  <TableHead>
                    <TableRow>
                      {filteredDays.map((column) => (
                        <TableCell
                          key={column + "dayname"}
                          sx={{
                            minWidth: "16rem",
                            textAlign: "center",
                          }}
                        >
                          <MDTypography
                            variant="h5"
                            fontWeight="bold"
                            color="dark"
                          >
                            {column} &nbsp; ({getTotalNumber(column)})
                          </MDTypography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  {/* Body Contents */}
                  <TableBody>
                    <TableRow>
                      {filteredDays.map((day, i) => (
                        <TableCell key={day + "cell"}>
                          {/* Now display Each Day Data */}
                          {getDayData(day).map((data, i) => (
                            <MDBox
                              key={data.id}
                              shadow="sm"
                              borderRadius="xl"
                              bgColor="grey-200"
                              coloredShadow="light"
                              p={2}
                              mb={2}
                              width="100%"
                              height={
                                data?.recent_attendance?.id ? "130px" : "110px"
                              }
                              display="flex"
                              flexDirection="column"
                              position="relative"
                            >
                              <MDTypography
                                variant="caption"
                                fontWeight="bold"
                                color="dark"
                                sx={{
                                  position: "absolute",
                                  right: 10,
                                  top: 5,
                                }}
                              >
                                {day.charAt(0)}
                                {day.charAt(1)}
                              </MDTypography>
                              <Link to={`/students/${data.student.id}`}>
                                <MDTypography
                                  variant="h6"
                                  fontWeight="bold"
                                  color="info"
                                >
                                  {data.student.first_name}{" "}
                                  {data.student.last_name}
                                </MDTypography>
                              </Link>
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="dark"
                              >
                                {" "}
                                <b>Start Time</b>&nbsp;:&nbsp;
                                {defaultconvertTimeTo12HourFormat(
                                  data.start_time
                                )}
                              </MDTypography>
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="dark"
                              >
                                <b>End Time</b>&nbsp;&nbsp;&nbsp;:&nbsp;
                                {defaultconvertTimeTo12HourFormat(
                                  data.end_time
                                )}
                              </MDTypography>
                              {data?.recent_attendance?.id &&
                                (daysDifferenceCalculator(
                                  data.recent_attendance.date
                                ) === 0 ? (
                                  <MDTypography
                                    variant="h8"
                                    fontWeight="bold"
                                    color="success"
                                  >
                                    Last Attended Today
                                  </MDTypography>
                                ) : (
                                  <MDTypography
                                    variant="h8"
                                    fontWeight="bold"
                                    color={getColor(
                                      data.recent_attendance.date
                                    )}
                                  >
                                    Last Attendance{" "}
                                    {daysDifferenceCalculator(
                                      data.recent_attendance.date
                                    )}{" "}
                                    days ago
                                  </MDTypography>
                                ))}
                            </MDBox>
                          ))}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </MDBox>
          )}

          {/* No Data Found */}
          {!processing && total === 0 && (
            <MDBox pt={3} px={4} pb={5}>
              <MDTypography variant="h7" color="error">
                No schedule found
              </MDTypography>
            </MDBox>
          )}
        </Card>
      </MDBox>

      {!processing && apiError && <APIError />}
      <Footer />
    </DashboardLayout>
  );
}

export default Schedule;
