import * as actions from "./actionTypes";

export const loginStarted = () => {
  return {
    type: actions.LOGIN_STARTED,
  };
};

export const loginSuccess = (data) => {
  return {
    type: actions.LOGIN_SUCCESS,
    payload: {
      access_token: data.token,
      userType: data.type,
    },
  };
};

export const loginError = () => {
  return {
    type: actions.LOGIN_ERROR,
  };
};

export const logoutStarted = () => {
  return {
    type: actions.LOGOUT_STARTED,
  };
};

export const logoutSuccess = () => {
  return {
    type: actions.LOGOUT_SUCCESS,
  };
};

export const logoutError = () => {
  return {
    type: actions.LOGOUT_ERROR,
  };
};
