import { useEffect, useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import paymentAPIService from "services/paymentAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddStudentClassCost(props) {
  const { open, onClose, studentId, studentName, existingCost } = props;

  const [costLoading, setCostLoading] = useState(false);
  const [costList, setCostList] = useState([]);

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  useEffect(() => {
    fetchAllCostData();
  }, []);

  const initialState = {
    student: "",
    costPerClass: "",
    note: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    costPerClass: false,
  });

  const fetchAllCostData = async () => {
    setCostLoading(true);
    setCostList([]);

    try {
      const allCostResponse = await paymentAPIService.getAllCostPerClass();

      setCostList(allCostResponse.data);
      setCostLoading(false);
    } catch (error) {
      setCostLoading(false);
    }
  };

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.costPerClass) {
      setErrors({ ...errors, costPerClass: true });
      return;
    } else {
      setErrors({ ...errors, costPerClass: false });
    }

    // Now that everything is ok
    setProcessing(true);

    try {
      let payload = {
        student: studentId,
        cost_per_class: inputs.costPerClass,
        note: inputs.note,
      };

      if (existingCost && existingCost?.length > 0) {
        const response = await paymentAPIService.updateStudentCostPerClass(
          payload,
          existingCost[0].id
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      } else {
        const response = await paymentAPIService.addStudentCostPerClass(
          payload
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      }
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage(error?.response?.data?.message);
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Update Attendace Cost
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>

      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Student
            </MDTypography>
            <MDInput
              value={studentName}
              name="studentName"
              readOnly
              fullWidth
            />
          </MDBox>

          {!costLoading && costList && (
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                Cost
              </MDTypography>
              <Select
                id="costSelector"
                value={inputs.costPerClass}
                name="costPerClass"
                onChange={changeHandler}
                fullWidth
                sx={{
                  "& .MuiSelect-icon": {
                    display: "flex",
                  },
                  height: "2.8rem",
                }}
                error={errors.costSelector}
              >
                {costList.map((cost, index) => (
                  <MenuItem key={cost.id} value={cost.id}>
                    $ {cost.cost}
                  </MenuItem>
                ))}
              </Select>
            </MDBox>
          )}

          {/* If No cost was pre configured */}
          {!costLoading && costList.length === 0 && (
            <MDTypography variant="h7" color="error" fontWeight="bold">
              No Cost has been configured. Please configure the cost per class.
            </MDTypography>
          )}
        </MDBox>
        <MDBox
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <MDTypography variant="h7" color="text" fontWeight="bold">
            Note
          </MDTypography>
          <MDInput
            type="text"
            value={inputs.note}
            name="note"
            onChange={changeHandler}
            error={errors.note}
            fullWidth
            multiline
            minRows="2"
          />
        </MDBox>
        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddStudentClassCost;
