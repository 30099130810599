import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteConfirmationDialog(props) {
  const { open, onClose, message } = props;

  const handleCancel = () => {
    onClose("No");
  };

  const handleOk = () => {
    onClose("Yes");
  };

  return (
    <Dialog TransitionComponent={Transition} maxWidth="xs" open={open}>
      <DialogTitle>
        <MDTypography color="error" fontWeight="bold">
          Please Confirm
        </MDTypography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <DialogActions>
          <MDButton variant="contained" color="light" onClick={handleCancel}>
            No
          </MDButton>
          <MDButton variant="contained" color="info" onClick={handleOk}>
            Yes
          </MDButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
