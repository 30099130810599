// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";

// Material Dashboard 2 React example components
import {
  CircularProgress,
  Icon,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import APIError from "components/ApiError";
import dateFormatter from "components/Formatter/DateFormatter";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import NotificationItem from "components/NotificationItem";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/bg6.jpg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import MDTypography from "components/MDTypography";
import AddProspectStudent from "./addNewProspectStudent";

function ProspectStudentSignUp() {
  const [addStudentDialogOpen, setAddStudentDialogOpen] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const handleSignUp = () => {
    setAddStudentDialogOpen(true);
  };

  const handleAddStudentDialogClose = (response) => {
    if (response === "Close") {
      setAddStudentDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Prospective Student has been added.");
      openSnackBar();
      setAddStudentDialogOpen(false);
    }
  };

  const renderAddStudentDialog = (
    <AddProspectStudent
      open={addStudentDialogOpen}
      onClose={handleAddStudentDialogClose}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <BasicLayout coverHeight="100vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="xxl"
          coloredShadow="success"
          mx={2}
          mt={-5}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography
            variant="h5"
            fontWeight="medium"
            color="white"
            fontStyle="italic"
          >
            Welcome to Creative Line
          </MDTypography>
        </MDBox>
        {/* Short promotional message */}
        <MDBox p={2}>
          <MDTypography variant="h7" fontWeight="regular">
            We’re thrilled to have you join us on this creative journey. Our
            school is dedicated to nurturing artistic talent and inspiring
            innovation at every level. Whether you're a beginner or an
            experienced artist, you’re in the right place to expand your skills
            and express your creativity.
          </MDTypography>
        </MDBox>
        {/* Register button */}
        <MDBox p={2}>
          <MDButton color="success" onClick={handleSignUp}>
            Sign Up Now
          </MDButton>
        </MDBox>
      </Card>

      {renderSnackBar}
      {renderAddStudentDialog}
    </BasicLayout>
  );
}

export default ProspectStudentSignUp;
