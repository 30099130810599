import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import { CircularProgress } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";

// Authentication layout components
import BasicLayout from "../../layouts/authentication/components/BasicLayout";

// Images
import bgImage from "../../assets/images/bg2.jpg";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AuthService from "services/auth-service";
import { loginSuccess } from "../../redux/actions/actions";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [credentialsErros, setCredentialsError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  const [processing, setProcessing] = useState(false);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    emailError: false,
    passwordError: false,
  });

  const addUserHandler = (newUser) => setUser(newUser);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = async (e) => {
    // check rememeber me?
    e.preventDefault();

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (inputs.email.trim().length === 0 || inputs.email.trim().length < 2) {
      setErrors({ ...errors, emailError: true });
      return;
    } else {
      setErrors({ ...errors, emailError: false });
    }

    // if (
    //   inputs.email.trim().length === 0 ||
    //   !inputs.email.trim().match(mailFormat)
    // ) {
    //   setErrors({ ...errors, emailError: true });
    //   return;
    // } else {
    //   setErrors({ ...errors, emailError: false });
    // }

    if (inputs.password.trim().length < 6) {
      setErrors({ ...errors, passwordError: true });
      return;
    } else {
      setErrors({ ...errors, passwordError: false });
    }

    const newUser = { username: inputs.email, password: inputs.password };
    addUserHandler(newUser);

    setProcessing(true);
    // Now Call In API To Authenticate
    try {
      const response = await AuthService.login(newUser);
      // Dispatch Login Success
      dispatch(loginSuccess(response.data));

      setProcessing(false);

      navigate("/");
    } catch (res) {
      if (res.hasOwnProperty("response")) {
        setCredentialsError(res.response.data.detail);
      } else {
        setCredentialsError(res.errors[0].detail);
      }
      setProcessing(false);
    }

    return () => {
      setInputs({
        email: "",
        password: "",
      });

      setErrors({
        emailError: false,
        passwordError: false,
      });
    };
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="xl"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            User Login
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={submitHandler}
          >
            <MDBox mb={2}>
              <MDInput
                type="text"
                label="UserName"
                fullWidth
                value={inputs.email}
                name="email"
                onChange={changeHandler}
                error={errors.emailError}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                name="password"
                value={inputs.password}
                onChange={changeHandler}
                error={errors.passwordError}
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                type="submit"
                disabled={processing}
              >
                sign in
                {processing && (
                  <CircularProgress
                    size={14}
                    sx={{ marginLeft: 1 }}
                    color="warning"
                  />
                )}
              </MDButton>
            </MDBox>
            {credentialsErros && (
              <MDTypography variant="caption" color="error" fontWeight="light">
                {credentialsErros}
              </MDTypography>
            )}
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot your password? Reset it{" "}
                <MDTypography
                  component={Link}
                  to="/auth/forgot-password"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  here
                </MDTypography>
              </MDTypography>
            </MDBox> */}
            {/* <MDBox mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
