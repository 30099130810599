import { useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import paymentAPIService from "services/paymentAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddCostPerClass(props) {
  const { open, onClose } = props;

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    costPerClass: "",
    note: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    costPerClass: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.costPerClass) {
      setErrors({ ...errors, costPerClass: true });
      return;
    } else {
      if (inputs.costPerClass <= 0) {
        setErrors({ ...errors, costPerClass: true });
        return;
      }
      setErrors({ ...errors, costPerClass: false });
    }

    // Now that everything is ok
    setProcessing(true);

    try {
      let payload = {
        cost: inputs.costPerClass,
        note: inputs.note,
      };

      const response = await paymentAPIService.addCostPerClass(payload);
      setProcessing(false);
      setInputs({ ...initialState });
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage(error?.response?.data?.message);
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Add Cost per Class
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>

      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Cost
            </MDTypography>
            <MDInput
              type="number"
              value={inputs.costPerClass}
              name="costPerClass"
              onChange={changeHandler}
              error={errors.costPerClass}
              fullWidth
              inputProps={{ min: 0 }}
            />
          </MDBox>
        </MDBox>
        <MDBox
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          width="100%"
        >
          <MDTypography variant="h7" color="text" fontWeight="bold">
            Note
          </MDTypography>
          <MDInput
            type="text"
            value={inputs.note}
            name="note"
            onChange={changeHandler}
            error={errors.note}
            fullWidth
            multiline
            minRows="2"
          />
        </MDBox>
        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddCostPerClass;
