const dateFormatter = (inputDate) => {
  // Create a Date object using the input time string
  const parts = inputDate.split("-");

  // Create a new Date object using the year, month, and day
  const dateObject = new Date(parts[0], parts[1] - 1, parts[2]);

  // Convert to mm/dd/yyyy
  const formattedDate = dateObject.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return formattedDate;
};

export default dateFormatter;
