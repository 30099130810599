import dateFormatter from "../DateFormatter";

const dayExtractor = (inputDate) => {
  // Create a Date object using the input time string
  const convertedFormatDate = dateFormatter(inputDate);
  const passedInDate = new Date(convertedFormatDate);
  // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const dayOfWeek = passedInDate.getDay();

  // Define an array of day names
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  return days[dayOfWeek];
};

export default dayExtractor;
