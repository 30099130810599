import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import profileBGImage from "assets/images/whiteBG3.jpg";
import errorIcon from "assets/icons/errorWarning.png";
export default function APIError(params) {
  const { message } = params;

  return (
    <Card
      sx={{
        backgroundImage: ({
          functions: { rgba, linearGradient },
          palette: { gradients },
        }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0),
            rgba(gradients.info.state, 0)
          )}, url(${profileBGImage})`,
        backgroundSize: "cover",
        backgroundPosition: "50%",
        overflow: "hidden",
        marginBottom: "50px",
      }}
    >
      <MDBox
        pt={4}
        pb={4}
        px={4}
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <MDBox component="img" src={errorIcon} alt="Brand" width="18rem" />
        <MDTypography variant="h5" color="error" textAlign="center" mt={5}>
          Something went wrong. Please refresh/reload the page.
        </MDTypography>
        <MDTypography variant="h7" color="error" textAlign="center" mt={1}>
          If problem persists please contact the CodeMero Team.
        </MDTypography>
      </MDBox>
      {message && message.length > 0 && (
        <MDTypography variant="h7" color="error" textAlign="center">
          {message}
        </MDTypography>
      )}
    </Card>
  );
}
