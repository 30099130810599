export const HintTexts = {
  meanAttendanceCount: {
    definition:
      "The average number of attendances per day over the specified period.",
    impact:
      "Helps to understand the typical daily attendance, providing insight into general trends. Higher mean attendance may indicate good engagement, while lower mean attendance could highlight potential issues.",
  },
  medianAttendanceCount: {
    definition:
      "The middle value of daily attendance counts when sorted in ascending order.",
    impact:
      "Gives a sense of the typical attendance level without being affected by extreme values. Useful for understanding central tendencies when outliers are present.",
  },
  modeAttendanceCount: {
    definition: "The most frequently occurring attendance count.",
    impact:
      "Identifies the most common attendance level, which can help in understanding the regular attendance pattern.",
  },
  stdDevAttendanceCount: {
    definition:
      "A measure of the variation or dispersion of daily attendance counts.",
    impact:
      "High standard deviation indicates high variability in attendance, which may require investigation. Consistent attendance is generally a sign of stable engagement.",
  },
  minAttendanceCount: {
    definition:
      "The lowest daily attendance count during the specified period.",
    impact:
      "Identifying the lowest attendance day can help in analyzing specific factors or events that led to poor attendance and addressing them in the future.",
  },
  maxAttendanceCount: {
    definition:
      "The highest daily attendance count during the specified period.",
    impact:
      "Understanding the peak attendance day helps in recognizing what drives high engagement, which can be replicated for future events or activities.",
  },
  attendanceRate: {
    definition:
      "The percentage of days with recorded attendance compared to the total number of days in the specified period.",
    impact:
      "Provides an overall view of participation consistency. A low attendance rate may indicate lack of interest or barriers to participation that need addressing.",
  },
  dailyAttendanceStats: {
    definition:
      "The attendance count for each day within the specified period.",
    impact:
      "Allows for day-by-day analysis of attendance, identifying specific trends, patterns, or anomalies that require action.",
  },
  meanPaidAmount: {
    definition: "The average paid amount per day over the specified period.",
    impact:
      "Helps understand the typical daily revenue, providing insights into customer spending behavior. A higher mean indicates increased revenue, while a lower mean may highlight potential issues or areas for improvement.",
  },
  medianPaidAmount: {
    definition:
      "The middle value of paid amounts when sorted in ascending order.",
    impact:
      "Offers a clearer picture of the typical payment without being influenced by extreme values. This is useful for understanding the central tendency, especially when there are significant outliers.",
  },
  modePaidAmount: {
    definition: "The most frequently occurring paid amount.",
    impact:
      "Identifies the most common payment level, which helps in understanding customer payment behavior. This can be useful for creating targeted offers or pricing strategies.",
  },
  stdDevPaidAmount: {
    definition: "A measure of the variation or dispersion of paid amounts.",
    impact:
      "A high standard deviation indicates variability in the paid amounts, which may need further investigation. Consistent payments (low standard deviation) suggest stable customer behavior, which is desirable for predictability.",
  },
  minPaidAmount: {
    definition: "The lowest paid amount during the specified period.",
    impact:
      "Understanding the minimum paid amount helps identify low-value transactions, which can inform pricing strategies or reveal opportunities to convert low-value customers into higher-value ones.",
  },
  maxPaidAmount: {
    definition: "The highest paid amount during the specified period.",
    impact:
      "Recognizing the peak payment amount can help identify successful campaigns or events that drove higher spending, providing insights for future revenue growth.",
  },
  dailyPaidStats: {
    definition:
      "The paid amount and count for each day within the specified period.",
    impact:
      "Enables day-by-day analysis of revenue, helping to identify trends, patterns, or anomalies that require action. This can support better decision-making and targeted interventions.",
  },
  totalPaidAmount: {
    definition: "The total of all paid amounts within the specified period.",
    impact:
      "Provides an overall measure of revenue for the entire period, crucial for evaluating business performance and setting future financial goals.",
  },
};
