import axiosInstance from "./htttp.service";

class TimeSlotApiService {
  axiosInstances = axiosInstance;

  // A list of All Available Time Slots
  getAvaiableTimeSlots = async (pageSize, pageIndex) => {
    return await this.axiosInstances.get(
      `/attendance/suggested-schedule-timeslot/?page_size=${pageSize}&page=${pageIndex}`
    );
  };

  // Delete Time Slot
  deleteTimeSlot = async (id) => {
    return await this.axiosInstances.delete(
      `/attendance/suggested-schedule-timeslot/${id}`
    );
  };

  // Add time slot
  addTimeSlot = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `/attendance/suggested-schedule-timeslot/`,
      payload,
      {
        headers,
      }
    );
  };
}

export default new TimeSlotApiService();
