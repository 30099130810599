import { logoutSuccess } from "../redux/actions/actions";
import axiosInstance from "./htttp.service";

export const setupAxiosInterceptors = (authSelector, navigate) => {
  const access_token = authSelector.access_token;
  if (access_token) {
    axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `token ${access_token}`;
  }

  const onRequestSuccess = async (config) => {
    return config;
  };

  const onRequestFail = (error) => {
    const status = error.status || error.response.status;
    if (status === 403 || status === 401) {
      navigate("/logout");
    }
    return Promise.reject(error);
  };

  axiosInstance.interceptors.request.use(onRequestSuccess, onRequestFail);

  const onResponseSuccess = (response) => response;
  const onResponseFail = (error) => {
    const status = error.status || error.response.status;
    if (status === 403 || status === 401) {
      navigate("/logout");
    }

    return Promise.reject(error);
  };

  axiosInstance.interceptors.response.use(onResponseSuccess, onResponseFail);
};
