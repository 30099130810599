import { useEffect, useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import corporateAPIService from "services/corporateAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AddCorporateCustomer(props) {
  const { open, onClose, existingDetails } = props;

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    business_name: "",
    business_email: "",
    business_phone: "",
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    address: "",
    note: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    business_name: false,
    business_email: false,
    business_phone: false,
    customer_name: false,
    customer_email: false,
    customer_phone: false,
    address: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
    setProcessing(false);
  };

  useEffect(() => {
    if (existingDetails?.id) {
      setInputs({
        business_name: existingDetails?.business_name,
        business_email: existingDetails?.business_email,
        business_phone: existingDetails?.business_phone,
        customer_name: existingDetails?.customer_name,
        customer_email: existingDetails?.customer_email,
        customer_phone: existingDetails?.customer_phone,
        address: existingDetails?.address,
        note: existingDetails?.note,
      });
    }
  }, [existingDetails]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.business_name) {
      setErrors({ ...errors, business_name: true });
      return;
    } else {
      setErrors({ ...errors, business_name: false });
    }

    if (!inputs.business_phone) {
      setErrors({ ...errors, business_phone: true });
      return;
    } else {
      setErrors({ ...errors, business_phone: false });
    }

    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    if (!inputs.business_email || inputs.business_email.trim().length === 0) {
      setErrors({ ...errors, business_email: false });
    } else {
      if (!inputs.business_email.trim().match(mailFormat)) {
        setErrors({ ...errors, business_email: true });
        return;
      }
    }

    // Customer
    if (!inputs.customer_name) {
      setErrors({ ...errors, customer_name: true });
      return;
    } else {
      setErrors({ ...errors, customer_name: false });
    }

    if (!inputs.customer_phone) {
      setErrors({ ...errors, customer_phone: true });
      return;
    } else {
      setErrors({ ...errors, customer_phone: false });
    }

    if (!inputs.customer_email || inputs.customer_email.trim().length === 0) {
      setErrors({ ...errors, customer_email: false });
    } else {
      if (!inputs.customer_email.trim().match(mailFormat)) {
        setErrors({ ...errors, customer_email: true });
        return;
      }
    }

    // Now that everything is verified
    setProcessing(true);
    try {
      let payload = {
        business_name: inputs.business_name,
        business_email: inputs.business_email,
        business_phone: inputs.business_phone,
        customer_name: inputs.customer_name,
        customer_email: inputs.customer_email,
        customer_phone: inputs.customer_phone,
        address: inputs.address,
        note: inputs.note,
      };

      if (existingDetails?.id) {
        const response = await corporateAPIService.updateCorporateCustomer(
          payload,
          existingDetails.id
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      } else {
        const response = await corporateAPIService.addCorporateCustomer(
          payload
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      }
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to add the Corporate Customer.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          {existingDetails ? "Edit" : "Add New"} Corporate Customer
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>
      <DialogContent>
        {/* Name */}
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          {/* Business Information */}
          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              business
            </Icon>
            Business Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="column" columnGap={2}>
              {/* Name */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Business Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.business_name}
                  name="business_name"
                  onChange={changeHandler}
                  error={errors.business_name}
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              {/* Email */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Business Email
                </MDTypography>
                <MDInput
                  type="email"
                  value={inputs.business_email}
                  name="business_email"
                  onChange={changeHandler}
                  error={errors.business_email}
                  fullWidth
                />
              </MDBox>

              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Business Phone
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.business_phone}
                  name="business_phone"
                  onChange={changeHandler}
                  error={errors.business_phone}
                  fullWidth
                />
              </MDBox>
            </MDBox>
          </MDBox>

          {/* Customer Information */}
          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              person
            </Icon>
            Customer Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="column" columnGap={2}>
              {/* Name */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Customer Name
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.customer_name}
                  name="customer_name"
                  onChange={changeHandler}
                  error={errors.customer_name}
                  fullWidth
                />
              </MDBox>
            </MDBox>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              {/* Email */}
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Customer Email
                </MDTypography>
                <MDInput
                  type="email"
                  value={inputs.customer_email}
                  name="customer_email"
                  onChange={changeHandler}
                  error={errors.customer_email}
                  fullWidth
                />
              </MDBox>

              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  <span style={{ color: "red" }}>*</span> Customer Phone
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.customer_phone}
                  name="customer_phone"
                  onChange={changeHandler}
                  error={errors.customer_phone}
                  fullWidth
                />
              </MDBox>
            </MDBox>
          </MDBox>

          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              home
            </Icon>{" "}
            Address Information
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox display="flex" flexDirection="row" columnGap={2}>
              <MDBox
                mb={2}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                width="100%"
              >
                <MDTypography variant="h7" color="text" fontWeight="bold">
                  Full Address
                </MDTypography>
                <MDInput
                  type="text"
                  value={inputs.address}
                  name="address"
                  onChange={changeHandler}
                  error={errors.address}
                  multiline
                  fullWidth
                  minRows="2"
                />
              </MDBox>
            </MDBox>
          </MDBox>

          {/* Note */}
          <MDTypography
            variant="h6"
            color="text"
            display="flex"
            columnGap={1}
            alignItems="center"
          >
            <Icon sx={{ color: "gray" }} fontSize="small">
              edit_note
            </Icon>{" "}
            Note
          </MDTypography>
          <MDBox p={2} shadow="md" mb={4}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDInput
                type="text"
                value={inputs.note}
                name="note"
                onChange={changeHandler}
                error={errors.note}
                fullWidth
                multiline
                minRows="2"
              />
            </MDBox>
          </MDBox>
        </MDBox>

        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddCorporateCustomer;
