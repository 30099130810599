import { useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { DaysList } from "assets/constants/Days";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import timeSlotAPIService from "services/timeSlotAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddTimeSlot(props) {
  const daysList = DaysList;
  const { open, onClose } = props;

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    day: "",
    start_time: "",
    end_time: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    day: false,
    start_time: false,
    end_time: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.day) {
      setErrors({ ...errors, day: true });
      return;
    } else {
      setErrors({ ...errors, day: false });
    }

    if (!inputs.start_time) {
      setErrors({ ...errors, start_time: true });
      return;
    } else {
      setErrors({ ...errors, start_time: false });
    }

    if (!inputs.end_time) {
      setErrors({ ...errors, end_time: true });
      return;
    } else {
      setErrors({ ...errors, end_time: false });
    }

    // Now that everything is ok
    setProcessing(true);

    try {
      let payload = {
        day: inputs.day,
        start_time: inputs.start_time,
        end_time: inputs.end_time,
      };

      const response = await timeSlotAPIService.addTimeSlot(payload);
      setProcessing(false);
      setInputs({ ...initialState });
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage(error?.response?.data?.message);
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Add Time Slot
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>

      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          {/* Day */}
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              <span style={{ color: "red" }}>*</span> Day
            </MDTypography>
            <Select
              id="day_selector"
              value={inputs.day}
              name="day"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.day}
            >
              {daysList.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
          </MDBox>

          {/* Time Picker */}
          <MDBox display="flex" flexDirection="row" columnGap={2}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                <span style={{ color: "red" }}>*</span> Start Time
              </MDTypography>
              <MDInput
                type="time"
                value={inputs.start_time}
                name="start_time"
                onChange={changeHandler}
                error={errors.start_time}
                fullWidth
              />
            </MDBox>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                <span style={{ color: "red" }}>*</span> End Time
              </MDTypography>
              <MDInput
                type="time"
                value={inputs.end_time}
                name="end_time"
                min={inputs.start_time} // Set the minimum value of end_time to start_time
                onChange={changeHandler}
                error={errors.end_time}
                fullWidth
              />
            </MDBox>
          </MDBox>
        </MDBox>

        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddTimeSlot;
