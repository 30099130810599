// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import {
  Icon,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import APIError from "components/ApiError";
import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import timeSlotAPIService from "services/timeSlotAPI-service";
import AddTimeSlot from "./addTimeSlot";

function TimeSlots() {
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addTimeSlotDialogOpen, setAddTimeSlotDialogOpen] = useState(false);

  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState("");

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const columns = ["Day", "Time", "Action"];

  useEffect(() => {
    fetchAllAvailableTimeSlots();
  }, []);

  const fetchAllAvailableTimeSlots = async () => {
    setProcessing(true);
    setApiError(false);

    try {
      const avaliableTimeSlots = await timeSlotAPIService.getAvaiableTimeSlots(
        rowsPerPage,
        page + 1
      );
      setRows(avaliableTimeSlots.data.results);
      setTotal(avaliableTimeSlots.data.count);

      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleAddCost = () => {
    setAddTimeSlotDialogOpen(true);
  };

  const handleTimeSlotDialogClose = (response) => {
    if (response === "Close") {
      setAddTimeSlotDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Time Slot has been added.");
      openSnackBar();
      fetchAllAvailableTimeSlots();
      setAddTimeSlotDialogOpen(false);
    }
  };

  const handleDeleteConfirmation = (id) => {
    setDialogMessage("Are you sure you want to remove this time slot?");
    setDeleteItem(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = async (response) => {
    if (response === "No") {
      setDeleteItem(null);
      setDeleteDialogOpen(false);
    } else {
      setDeleteProcessing(true);

      try {
        const deleteResponse = await timeSlotAPIService.deleteTimeSlot(
          deleteItem
        );
        setSnackBarColor("success");
        setSnackBarIcon("check");
        setSnackBarTitle("Success");
        setSnackBarMessage("Time Slot has been deleted.");
        openSnackBar();

        fetchAllAvailableTimeSlots();
        setDeleteProcessing(false);
        setDeleteItem(null);
        setDeleteDialogOpen(false);
      } catch (error) {
        setSnackBarColor("error");
        setSnackBarIcon("warning");
        setSnackBarTitle("Error");
        setSnackBarMessage("Failed to remove the Time Slot.");
        openSnackBar();
        setDeleteProcessing(false);
        setDeleteDialogOpen(false);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    fetchAllAvailableTimeSlots();
  }, [page, rowsPerPage]);

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  const renderTimeSlotDelete = (
    <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleDeleteClose}
      message={dialogMessage}
    />
  );

  const renderTimeSlotDialog = (
    <AddTimeSlot
      open={addTimeSlotDialogOpen}
      onClose={handleTimeSlotDialogClose}
    />
  );

  return (
    <MDBox pt={6} pb={3}>
      {/* Add Button */}
      <MDBox
        mb={5}
        mt={-5}
        pr={2}
        display="flex"
        justifyContent="end"
        gap={2}
        flexWrap="wrap"
      >
        <MDButton
          variant="contained"
          color="dark"
          type="button"
          onClick={handleAddCost}
        >
          + Add Time Slot
        </MDButton>
      </MDBox>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            {/* Heading */}
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Available Time Slots
              </MDTypography>
            </MDBox>

            {/* Progress Bar */}
            {(processing || deleteProcessing) && (
              <MDBox pt={2} px={2}>
                <LinearProgress
                  color="info"
                  variant="indeterminate"
                  sx={{ overflow: "hidden" }}
                />
              </MDBox>
            )}

            {/* No Data */}
            {!processing && !apiError && total === 0 && (
              <MDBox pt={3} px={4} pb={5}>
                <MDTypography variant="h7" color="error">
                  No time slot record found
                </MDTypography>
              </MDBox>
            )}

            {/* Table */}
            {!processing && total > 0 && (
              <MDBox pt={3} px={1} pb={5}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table>
                    {/* Header Columns */}
                    <TableHead>
                      <TableRow>
                        {columns.map((column, i) => (
                          <TableCell key={i}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Body Contents */}
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow key={i}>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="medium">
                              {row.day}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {defaultconvertTimeTo12HourFormat(row.start_time)}{" "}
                              - {defaultconvertTimeTo12HourFormat(row.end_time)}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <Icon
                              sx={{ cursor: "pointer", fontWeight: "regular" }}
                              fontSize="medium"
                              color="error"
                              onClick={() => {
                                handleDeleteConfirmation(row.id);
                              }}
                            >
                              delete_forever
                            </Icon>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </MDBox>
            )}

            {/* Api Error */}
            {!processing && apiError && <APIError />}
          </Card>
        </Grid>
      </Grid>
      {renderSnackBar}
      {renderTimeSlotDelete}
      {renderTimeSlotDialog}
    </MDBox>
  );
}

export default TimeSlots;
