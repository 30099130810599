import * as actions from "../actions/actionTypes";

const initialState = {
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false,
  access_token: "",
  userType: "",
};

export const authUser = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true,
        access_token: action.payload.access_token,
        userType: action.payload.userType,
        loginSuccess: true,
        loginError: false,
      };
    }
    case actions.LOGOUT_SUCCESS: {
      return {
        initialState,
      };
    }
    default:
      return state;
  }
};
