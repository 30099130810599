import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// @mui material components
import AppBar from "@mui/material/AppBar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

// Material Dashboard 2 React components
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import rerportBGImage from "assets/images/bg1.jpg";
import calendarBGImage from "assets/images/calendar.jpg";
import profileBGImage from "assets/images/headerbg.jpg";
import paymentBGImage from "assets/images/payment.jpg";
import avatar from "assets/images/person.png";

import { LinearProgress } from "@mui/material";
import APIError from "components/ApiError";
import StudentApiService from "services/studentAPI-service";
import StudentPaymentDetails from "../paymentDetails";
import ProfileDetails from "../profileDetails";
import StudentScheduleDetails from "../scheduleDetails";
import StudentReports from "../studentReports";

function StudentDetails() {
  let { id } = useParams();

  const [studentName, setStudentName] = useState("");
  const [studentDetails, setStudentDetails] = useState([]);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState("profile");
  const [processing, setProcessing] = useState(true);
  const [apiError, setApiError] = useState(false);

  useEffect(() => {
    fetchStudentInformation();
  }, []);

  const fetchStudentInformation = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const response = await StudentApiService.getStudentInformation(id);
      const {
        student_UUID,
        legacy,
        soft_delete,
        student_id,
        ...studentInformation
      } = response.data;
      setStudentName(response.data.first_name + " " + response.data.last_name);
      setStudentDetails(studentInformation);
      setProcessing(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const handleProfileDetailsChange = (newStudentDetails) => {
    setStudentDetails(newStudentDetails);
    setStudentName(
      newStudentDetails.first_name + " " + newStudentDetails.last_name
    );
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox position="relative" mb={5}>
        <MDBox
          display="flex"
          alignItems="center"
          position="relative"
          minHeight="14.75rem"
          borderRadius="xl"
          sx={{
            backgroundImage: ({
              functions: { rgba, linearGradient },
              palette: { gradients },
            }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.2),
                rgba(gradients.info.state, 0.2)
              )}, url(${
                tabValue === "profile"
                  ? profileBGImage
                  : tabValue === "payments"
                  ? paymentBGImage
                  : tabValue === "attendance"
                  ? calendarBGImage
                  : rerportBGImage
              })`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            overflow: "hidden",
          }}
        />
        <Card
          sx={{
            position: "relative",
            mt: -8,
            mx: 3,
            py: 2,
            px: 2,
          }}
        >
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <MDAvatar
                src={avatar}
                alt="profile-image"
                size="xl"
                shadow="sm"
              />
            </Grid>
            <Grid item>
              <MDBox height="100%" mt={0.5} lineHeight={1}>
                <MDTypography variant="h4" fontWeight="medium">
                  {studentName}
                </MDTypography>
                <MDTypography
                  variant="button"
                  color="text"
                  fontWeight="regular"
                >
                  Student
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={8} lg={6} sx={{ ml: "auto" }}>
              <AppBar position="static">
                <Tabs
                  orientation={tabsOrientation}
                  value={tabValue}
                  onChange={handleSetTabValue}
                >
                  <Tab
                    value="profile"
                    label="Profile"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        settings
                      </Icon>
                    }
                  />
                  <Tab
                    value="attendance"
                    label="Attendance"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        calendar_month
                      </Icon>
                    }
                  />
                  <Tab
                    value="payments"
                    label="Payments"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        paid
                      </Icon>
                    }
                  />
                  <Tab
                    value="report"
                    label="Report"
                    icon={
                      <Icon fontSize="small" sx={{ mt: -0.25 }}>
                        description
                      </Icon>
                    }
                  />
                </Tabs>
              </AppBar>
            </Grid>
          </Grid>

          {/* Progress Bar */}
          {processing && (
            <MDBox pt={2} px={2}>
              <LinearProgress
                color="info"
                variant="indeterminate"
                sx={{ overflow: "hidden" }}
              />
            </MDBox>
          )}

          {/* Views */}
          {!processing && !apiError && (
            <MDBox mt={5} width="100%">
              {tabValue === "profile" ? (
                <ProfileDetails
                  id={id}
                  onProfileDetailsChange={handleProfileDetailsChange}
                />
              ) : tabValue === "payments" ? (
                <StudentPaymentDetails id={id} studentName={studentName} />
              ) : tabValue === "attendance" ? (
                <StudentScheduleDetails id={id} studentName={studentName} />
              ) : (
                <StudentReports id={id} studentName={studentName} />
              )}
            </MDBox>
          )}

          {/* API Error */}
          {!processing && apiError && <APIError />}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default StudentDetails;
