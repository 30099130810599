import {
  Grid,
  Icon,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import phoneNumberMask from "components/Formatter/PhoneMask";
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import studentAPIService from "services/studentAPI-service";
import AddStudentSchedule from "../addSchedule";
import AddStudentClassCost from "../addStudentClassCost";
import AddStudentConfigDetails from "../addConfigDetails";
import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import AddStudentDialog from "../addStudent";
import { useSelector } from "react-redux";
import dateFormatter from "components/Formatter/DateFormatter";
function ProfileDetails(props) {
  const userSelector = useSelector((state) => state.auth);
  const { id, onProfileDetailsChange } = props;

  const [addScheduleOpen, setAddScheduleOpen] = useState(false);
  const [addCostOpen, setAddCostOpen] = useState(false);
  const [editStudentOpen, setEditStudentOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [processing, setProcessing] = useState(true);
  const [detailsProcessing, setDetailsProcessing] = useState(true);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [costProcessing, setCostProcessing] = useState(true);
  const [studentConfigProcessing, setStudentConfigProcessing] = useState(true);
  const [editStudentConfig, setEditStudentConfig] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState("");
  const [studentDetails, setStudentDetails] = useState([]);
  const [studentSchedules, setStudentSchedules] = useState([]);
  const [studentConfigDetails, setStudentConfigDetails] = useState([]);
  const [studentCost, setStudentCost] = useState([]);
  const [total, setTotal] = useState(0);

  const [columns, setColumns] = useState(["Day", "Start Time", "End Time"]);

  //Snack Bar
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const dayMap = new Map()
    .set("0", "Sunday")
    .set("1", "Monday")
    .set("2", "Tuesday")
    .set("3", "Wednesday")
    .set("4", "Thursday")
    .set("5", "Friday")
    .set("6", "Saturday");

  useEffect(() => {
    fetchStudentInformation();
    fetchStudentScheduleData();
    fetchStudentClassCostData();
    fetchStudentConfigDetails();
  }, []);

  useEffect(() => {
    if (Array.of("superadmin", "admin").includes(userSelector.userType)) {
      setColumns(["Day", "Start Time", "End Time", "Action"]);
    } else {
      setColumns(["Day", "Start Time", "End Time"]);
    }
  }, [useSelector]);

  const fetchStudentInformation = async () => {
    setDetailsProcessing(true);
    setApiError(false);
    try {
      const response = await studentAPIService.getStudentInformation(id);
      const {
        student_UUID,
        legacy,
        soft_delete,
        student_id,
        ...studentInformation
      } = response.data;
      setStudentDetails(studentInformation);
      onProfileDetailsChange(studentInformation);
      setDetailsProcessing(false);
    } catch (error) {
      setApiError(true);
      setDetailsProcessing(false);
    }
  };

  const fetchStudentConfigDetails = async () => {
    setStudentConfigProcessing(true);
    setApiError(false);
    try {
      const response = await studentAPIService.getStudentConfigDetails(id);
      setStudentConfigDetails(response.data?.results);
      setStudentConfigProcessing(false);
    } catch (error) {
      setApiError(true);
      setStudentConfigProcessing(false);
    }
  };

  const handleStudentConfigEdit = () => {
    setEditStudentConfig(true);
  };

  const fetchStudentScheduleData = async () => {
    setProcessing(true);
    setApiError(false);

    try {
      const studentScheduleResponse =
        await studentAPIService.getIndividualStudentSchedules(id);

      setTotal(studentScheduleResponse.data.count);
      setStudentSchedules(studentScheduleResponse.data.results);

      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const fetchStudentClassCostData = async () => {
    setStudentCost([]);
    setCostProcessing(true);
    setApiError(false);

    try {
      const studentCostResponse =
        await studentAPIService.getStudentsCostPerClass(id);

      setStudentCost(studentCostResponse.data.results);
      setCostProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setCostProcessing(false);
    }
  };
  const handleAddSchedule = () => {
    setAddScheduleOpen(true);
  };

  const handleAddScheduleClose = (response) => {
    if (response === "Close") {
      setAddScheduleOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Student Schedule has been added.");
      openSnackBar();
      fetchStudentScheduleData();
      setAddScheduleOpen(false);
    }
  };

  const handleAddCost = () => {
    setAddCostOpen(true);
  };

  const handleAddCostClose = (response) => {
    if (response === "Close") {
      setAddCostOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Student cost per class has been added.");
      openSnackBar();
      fetchStudentClassCostData();
      setAddCostOpen(false);
    }
  };

  const handleDeleteConfirmation = (id) => {
    setDialogMessage("Are you sure you want to remove this schedule record?");
    setDeleteItem(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteClose = async (response) => {
    if (response === "No") {
      setDeleteItem(null);
      setDeleteDialogOpen(false);
    } else {
      setDeleteProcessing(true);

      try {
        const deleteResponse = await studentAPIService.deleteStudentSchedule(
          deleteItem
        );
        setSnackBarColor("success");
        setSnackBarIcon("check");
        setSnackBarTitle("Success");
        setSnackBarMessage("Student Schedule has been updated.");
        openSnackBar();

        fetchStudentScheduleData();
        setDeleteProcessing(false);
        setDeleteItem(null);
        setDeleteDialogOpen(false);
      } catch (error) {
        setSnackBarColor("error");
        setSnackBarIcon("warning");
        setSnackBarTitle("Error");
        setSnackBarMessage("Failed to remove the Schedule Record.");
        openSnackBar();
        setDeleteProcessing(false);
        setDeleteDialogOpen(false);
      }
    }
  };

  const handleProfileEdit = () => {
    setEditStudentOpen(true);
  };

  const handleAddStudentDialogClose = (response) => {
    if (response === "Close") {
      setEditStudentOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Student Details have been updated.");
      openSnackBar();
      fetchStudentInformation();
      setEditStudentOpen(false);
    }
  };

  const handleAddConfigClose = (response) => {
    if (response === "Close") {
      setEditStudentConfig(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Student Details have been updated.");
      openSnackBar();
      fetchStudentConfigDetails();
      setEditStudentConfig(false);
    }
  };

  const renderStudentConfigurationDialog = (
    <AddStudentConfigDetails
      open={editStudentConfig}
      onClose={handleAddConfigClose}
      existingDetails={studentConfigDetails}
      studentId={studentDetails.id}
      studentName={studentDetails.first_name + " " + studentDetails.last_name}
    />
  );

  const renderAddStudentDialog = (
    <AddStudentDialog
      open={editStudentOpen}
      onClose={handleAddStudentDialogClose}
      existingDetails={studentDetails}
    />
  );

  const renderAddSchduleDialog = (
    <AddStudentSchedule
      studentId={studentDetails.id}
      studentName={studentDetails.first_name + " " + studentDetails.last_name}
      open={addScheduleOpen}
      onClose={handleAddScheduleClose}
    />
  );

  const renderAddCostDialog = (
    <AddStudentClassCost
      studentId={studentDetails.id}
      studentName={studentDetails.first_name + " " + studentDetails.last_name}
      existingCost={studentCost}
      open={addCostOpen}
      onClose={handleAddCostClose}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  const renderScheduleDelete = (
    <DeleteConfirmationDialog
      open={deleteDialogOpen}
      onClose={handleDeleteClose}
      message={dialogMessage}
    />
  );

  return (
    <Grid container mb={3}>
      {/* Profile Information */}
      <Grid item xs={12} md={6} alignItems="center" pt={2} px={2}>
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Student's Profile Information
          {Array.of("superadmin", "admin").includes(userSelector.userType) && (
            <MDButton
              iconOnly
              color="secondary"
              variant="text"
              size="medium"
              onClick={handleProfileEdit}
            >
              <Icon>edit</Icon>
            </MDButton>
          )}
        </MDTypography>

        {/* Progress Bar */}
        {detailsProcessing && (
          <MDBox pt={2} px={2}>
            <LinearProgress
              color="info"
              variant="indeterminate"
              sx={{ overflow: "hidden" }}
            />
          </MDBox>
        )}

        {!detailsProcessing && (
          <MDBox pt={2}>
            {/* Student Name */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Name
                </MDTypography>
              </Grid>
              <Grid item>
                <MDTypography variant="button" fontWeight="medium" color="text">
                  {studentDetails.first_name} {studentDetails.last_name}
                </MDTypography>
              </Grid>
            </Grid>

            {/* Parent 1 Details */}
            {studentDetails?.parent_1_first_name && (
              <Grid container>
                <Grid item sx={{ width: "140px" }}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Parent Details
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    <b>
                      {" "}
                      {studentDetails?.parent_1_first_name}{" "}
                      {studentDetails?.parent_1_last_name}
                    </b>
                    {studentDetails?.contact_1_email && <br />}
                    {studentDetails?.contact_1_email}
                    {studentDetails?.phone_1 && <br />}
                    {phoneNumberMask(studentDetails?.phone_1)}
                  </MDTypography>
                </Grid>
              </Grid>
            )}

            {/* Parent 2 Details */}
            {studentDetails?.parent_2_first_name && (
              <Grid container>
                <Grid item sx={{ width: "140px" }}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Parent Details
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    <b>
                      {studentDetails?.parent_2_first_name}{" "}
                      {studentDetails?.parent_2_last_name}
                    </b>
                    {studentDetails?.contact_2_email && <br />}
                    {studentDetails?.contact_2_email}
                    {studentDetails?.phone_2 && <br />}
                    {phoneNumberMask(studentDetails?.phone_2)}
                  </MDTypography>
                </Grid>
              </Grid>
            )}

            {/* Student Address Details */}
            {studentDetails?.address_1 && (
              <Grid container>
                <Grid item sx={{ width: "140px" }}>
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Address
                  </MDTypography>
                </Grid>
                <Grid item>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    {studentDetails?.address_1}
                    {studentDetails?.address_2 && ", "}
                    {studentDetails?.address_2}
                    {/* City, state, and zip */}
                    {studentDetails?.city && <br />}
                    {studentDetails?.city}
                    {studentDetails?.state && ", "}
                    {studentDetails?.state}
                    {studentDetails?.zip_code && ", "}
                    {studentDetails?.zip_code}
                  </MDTypography>
                </Grid>
              </Grid>
            )}

            {/* Student Unpaid Amount Details */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Unpaid Amount
                </MDTypography>
              </Grid>
              <Grid item>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  color={
                    studentDetails?.unpaid_amount <= 0 ? "success" : "error"
                  }
                >
                  $ {studentDetails?.unpaid_amount}
                </MDTypography>
              </Grid>
            </Grid>

            {/* Student Status */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Status
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBadge
                  badgeContent={studentDetails.status}
                  color={
                    studentDetails.status === "Active"
                      ? "success"
                      : studentDetails.status === "Inactive"
                      ? "error"
                      : studentDetails.status === "Prospective"
                      ? "warning"
                      : "dark"
                  }
                  variant="gradient"
                  size="lg"
                />
              </Grid>

              {/* Student Note */}
              {studentDetails?.note && (
                <Grid container>
                  <Grid item sx={{ width: "140px" }}>
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Note
                    </MDTypography>
                  </Grid>
                  <Grid item>
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      fontStyle="italic"
                      color="text"
                    >
                      {studentDetails?.note}
                    </MDTypography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </MDBox>
        )}

        {/* For Student config */}
        <MDTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
          mt={6}
        >
          Student's Details
          {Array.of("superadmin", "admin").includes(userSelector.userType) && (
            <MDButton
              iconOnly
              color="secondary"
              variant="text"
              size="medium"
              onClick={handleStudentConfigEdit}
            >
              <Icon>edit</Icon>
            </MDButton>
          )}
        </MDTypography>
        {/* Progress Bar */}
        {studentConfigProcessing && (
          <MDBox pt={2} px={2}>
            <LinearProgress
              color="info"
              variant="indeterminate"
              sx={{ overflow: "hidden" }}
            />
          </MDBox>
        )}
        {/* Display the student details config */}
        {!studentConfigProcessing && studentConfigDetails.length > 0 && (
          <MDBox pt={2}>
            {/*  Enter Art Contests */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Enter Art Contests
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBadge
                  badgeContent={
                    studentConfigDetails.at(0).enter_art_contests ? "Yes" : "No"
                  }
                  color={
                    studentConfigDetails.at(0).enter_art_contests
                      ? "success"
                      : "error"
                  }
                  variant="gradient"
                  size="lg"
                />
              </Grid>
            </Grid>

            {/*  Exhibit Artwork */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Exhibit Artwork
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBadge
                  badgeContent={
                    studentConfigDetails.at(0).exhibit_artwork ? "Yes" : "No"
                  }
                  color={
                    studentConfigDetails.at(0).exhibit_artwork
                      ? "success"
                      : "error"
                  }
                  variant="gradient"
                  size="lg"
                />
              </Grid>
            </Grid>

            {/* Receive Emails */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Receive Emails
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBadge
                  badgeContent={
                    studentConfigDetails.at(0).receive_emails ? "Yes" : "No"
                  }
                  color={
                    studentConfigDetails.at(0).receive_emails
                      ? "success"
                      : "error"
                  }
                  variant="gradient"
                  size="lg"
                />
              </Grid>
            </Grid>

            {/* Receive Text */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Receive Text
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBadge
                  badgeContent={
                    studentConfigDetails.at(0).receive_text ? "Yes" : "No"
                  }
                  color={
                    studentConfigDetails.at(0).receive_text
                      ? "success"
                      : "error"
                  }
                  variant="gradient"
                  size="lg"
                />
              </Grid>
            </Grid>

            {/* sell artwork permission */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Sell Artwork
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBadge
                  badgeContent={
                    studentConfigDetails.at(0).sell_artwork_permission
                      ? "Yes"
                      : "No"
                  }
                  color={
                    studentConfigDetails.at(0).sell_artwork_permission
                      ? "success"
                      : "error"
                  }
                  variant="gradient"
                  size="lg"
                />
              </Grid>
            </Grid>

            {/* Payment Method */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Payment Method
                </MDTypography>
              </Grid>
              <Grid item>
                <MDBadge
                  badgeContent={
                    studentConfigDetails.at(0).payment_method == 1
                      ? "Cash"
                      : studentConfigDetails.at(0).payment_method == 2
                      ? "Zelle"
                      : studentConfigDetails.at(0).payment_method == 3
                      ? "Venmo"
                      : studentConfigDetails.at(0).payment_method == 4
                      ? "Paypal"
                      : "Check"
                  }
                  color={
                    studentConfigDetails.at(0).payment_method == 1
                      ? "success"
                      : studentConfigDetails.at(0).payment_method == 2
                      ? "info"
                      : studentConfigDetails.at(0).payment_method == 3
                      ? "primary"
                      : studentConfigDetails.at(0).payment_method == 4
                      ? "secondary"
                      : "dark"
                  }
                  variant="gradient"
                  size="sm"
                />
              </Grid>
            </Grid>

            {/* Last modified */}
            <Grid container>
              <Grid item sx={{ width: "140px" }}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Last Modified
                </MDTypography>
              </Grid>
              <Grid item>
                <MDTypography variant="button" fontWeight="medium" color="text">
                  {dateFormatter(studentConfigDetails.at(0).last_updated)}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        )}

        {!studentConfigProcessing && studentConfigDetails.length == 0 && (
          <MDTypography variant="h8" fontWeight="bold" color="text" mb={4}>
            No Details Found
          </MDTypography>
        )}
      </Grid>

      {/* Schedule Information */}
      <Grid item xs={12} md={6} alignItems="center" pt={2} px={2}>
        <Grid container spacing={2}>
          <Grid item sm={12} sx={{ width: "100%" }}>
            <MDBox
              variant="contained"
              sx={{ height: "100%" }}
              shadow="sm"
              coloredShadow="dark"
              borderRadius="xl"
              bgColor="grey-200"
            >
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
                pt={3}
                px={2}
                textAlign="center"
              >
                Student's Schedule Information
              </MDTypography>

              {/* Add Button */}
              {Array.of("superadmin", "admin").includes(
                userSelector.userType
              ) && (
                <MDBox
                  mt={2}
                  pr={2}
                  display="flex"
                  justifyContent="end"
                  gap={2}
                  flexWrap="wrap"
                >
                  <MDButton
                    variant="contained"
                    color="dark"
                    type="button"
                    onClick={handleAddSchedule}
                  >
                    + Add Schedule
                  </MDButton>
                </MDBox>
              )}
              {/* Progress Bar */}
              {(processing || deleteProcessing) && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {/* No Data */}
              {!processing && !apiError && total === 0 && (
                <MDBox pt={3} px={4} pb={5}>
                  <MDTypography variant="h7" color="error">
                    No schedule record found
                  </MDTypography>
                </MDBox>
              )}

              {/*  Data  Found*/}
              {!processing && !apiError && total > 0 && (
                <MDBox pt={3} px={1} pb={5}>
                  <TableContainer component={Paper}>
                    <Table>
                      {/* Header Columns */}
                      <TableHead>
                        <TableRow>
                          {columns.map((column, i) => (
                            <TableCell key={i}>{column}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {/* Body Contents */}
                      <TableBody>
                        {studentSchedules.map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell>
                              <MDTypography
                                variant="h7"
                                fontWeight="bold"
                                color="info"
                              >
                                {dayMap.get(row.day)}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {defaultconvertTimeTo12HourFormat(
                                  row.start_time
                                )}
                              </MDTypography>
                            </TableCell>
                            <TableCell>
                              <MDTypography variant="h7" fontWeight="regular">
                                {defaultconvertTimeTo12HourFormat(row.end_time)}
                              </MDTypography>
                            </TableCell>
                            {/* Add Button */}
                            {Array.of("superadmin", "admin").includes(
                              userSelector.userType
                            ) && (
                              <TableCell>
                                <Icon
                                  sx={{
                                    cursor: "pointer",
                                    fontWeight: "regular",
                                    color: "red",
                                  }}
                                  fontSize="medium"
                                  onClick={() => {
                                    handleDeleteConfirmation(row.id);
                                  }}
                                >
                                  delete_forever
                                </Icon>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MDBox>
              )}
            </MDBox>
          </Grid>
          <Grid item sm={12} sx={{ width: "100%" }}>
            <MDBox
              variant="contained"
              sx={{ height: "100%" }}
              shadow="sm"
              coloredShadow="dark"
              borderRadius="xl"
              bgColor="grey-200"
            >
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
                pt={3}
                px={2}
                textAlign="center"
              >
                Student's Cost Information
              </MDTypography>

              {/* Add Button */}
              {/* Add Button */}
              {Array.of("superadmin", "admin").includes(
                userSelector.userType
              ) && (
                <MDBox
                  mt={2}
                  pr={2}
                  display="flex"
                  justifyContent="end"
                  gap={2}
                  flexWrap="wrap"
                >
                  <MDButton
                    variant="contained"
                    color="dark"
                    type="button"
                    onClick={handleAddCost}
                  >
                    + Update Cost per class
                  </MDButton>
                </MDBox>
              )}
              {/* Progress Bar */}
              {costProcessing && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {/* No Data */}
              {!costProcessing && !apiError && studentCost?.length === 0 && (
                <MDBox pt={3} px={4} pb={5}>
                  <MDTypography variant="h7" color="error">
                    No Cost per class record found
                  </MDTypography>
                </MDBox>
              )}

              {/*  Data  Found*/}
              {!processing && !apiError && studentCost.length > 0 && (
                <MDBox pt={3} px={1} pb={5}>
                  <TableContainer component={Paper}>
                    <Table>
                      {/* Header Columns */}
                      <TableHead>
                        <TableRow>
                          <TableCell>Cost per class</TableCell>
                          <TableCell>
                            $ {studentCost[0]?.cost_per_class?.cost}
                          </TableCell>
                        </TableRow>
                        {studentCost[0]?.note && (
                          <TableRow>
                            <TableCell>{studentCost[0]?.note}</TableCell>
                          </TableRow>
                        )}
                      </TableHead>
                    </Table>
                  </TableContainer>
                </MDBox>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </Grid>

      {renderSnackBar}
      {renderScheduleDelete}
      {addScheduleOpen && renderAddSchduleDialog}
      {addCostOpen && renderAddCostDialog}
      {editStudentOpen && renderAddStudentDialog}
      {editStudentConfig && renderStudentConfigurationDialog}
    </Grid>
  );
}

export default ProfileDetails;
