import axiosInstance from "./htttp.service";

class AnalyticsApiService {
  axiosInstances = axiosInstance;

  // Most Recent Attendance
  getStudentStatistics = async () => {
    return await this.axiosInstances.get(`/analysis/student-stats/`);
  };

  // Most  Attendance Stats
  getAttendanceStatistics = async (startDate, endDate) => {
    return await this.axiosInstances.get(
      `/analysis/attendance-stats/?date_after=${startDate}&date_before=${endDate}`
    );
  };

  // Most Paid Stats
  getPaidAmountStatistics = async (startDate, endDate) => {
    return await this.axiosInstances.get(
      `/analysis/paid-amount-stats/?date_after=${startDate}&date_before=${endDate}`
    );
  };
}

export default new AnalyticsApiService();
