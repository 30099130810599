import { useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import attendanceAPIService from "services/attendanceAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

function AddAttendanceDialog(props) {
  const { open, onClose, studentId, studentName } = props;
  const today = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-");

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    present: true,
    date: today,
    startTime: "",
    endTime: "",
    nOfClasses: 1,
    classType: "In person",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    present: false,
    date: false,
    startTime: false,
    endTime: false,
    nOfClasses: false,
    classType: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.date) {
      setErrors({ ...errors, date: true });
      return;
    } else {
      setErrors({ ...errors, date: false });
    }

    if (!inputs.startTime) {
      setErrors({ ...errors, startTime: true });
      return;
    } else {
      setErrors({ ...errors, startTime: false });
    }

    if (!inputs.endTime) {
      setErrors({ ...errors, endTime: true });
      return;
    } else {
      setErrors({ ...errors, endTime: false });
    }

    if (!inputs.nOfClasses) {
      setErrors({ ...errors, nOfClasses: true });
      return;
    } else {
      setErrors({ ...errors, nOfClasses: false });
    }

    if (!inputs.classType) {
      setErrors({ ...errors, classType: true });
      return;
    } else {
      setErrors({ ...errors, classType: false });
    }

    // Now that everything is ok
    setProcessing(true);

    try {
      let payload = {
        student: studentId,
        present: inputs.present,
        date: inputs.date,
        start_time: inputs.startTime,
        end_time: inputs.endTime,
        Number_of_classes: inputs.nOfClasses,
        Class_type: inputs.classType,
      };

      const response = await attendanceAPIService.addAttendance(payload);
      setProcessing(false);
      setInputs({ ...initialState });
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage(error.response.data.message);
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Add Attendance
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>

      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Student
            </MDTypography>
            <MDInput
              value={studentName}
              name="studentName"
              readOnly
              fullWidth
            />
          </MDBox>
          <MDBox display="flex" flexDirection="row" columnGap={2}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                Date
              </MDTypography>
              <MDInput
                type="date"
                value={inputs.date}
                name="date"
                onChange={changeHandler}
                error={errors.date}
                InputProps={{
                  inputProps: {
                    max: today,
                  },
                }}
                fullWidth
              />
            </MDBox>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                Present
              </MDTypography>
              <Select
                id="statusSelectLabel"
                value={inputs.present}
                name="present"
                onChange={changeHandler}
                error={errors.present}
                fullWidth
                sx={{
                  "& .MuiSelect-icon": {
                    display: "flex",
                  },
                  height: "2.8rem",
                }}
              >
                <MenuItem value={true}>Present</MenuItem>
                <MenuItem value={false}>Absent</MenuItem>
              </Select>
            </MDBox>
          </MDBox>
          <MDBox display="flex" flexDirection="row" columnGap={2}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                Start Time
              </MDTypography>
              <MDInput
                type="time"
                value={inputs.startTime}
                name="startTime"
                onChange={changeHandler}
                error={errors.startTime}
                fullWidth
              />
            </MDBox>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                End Time
              </MDTypography>
              <MDInput
                type="time"
                value={inputs.endTime}
                name="endTime"
                onChange={changeHandler}
                error={errors.endTime}
                fullWidth
              />
            </MDBox>
          </MDBox>
          <MDBox display="flex" flexDirection="row" columnGap={2}>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                Number of Classes
              </MDTypography>

              <Select
                id="classSelect"
                value={inputs.nOfClasses}
                name="nOfClasses"
                onChange={changeHandler}
                error={errors.nOfClasses}
                fullWidth
                sx={{
                  "& .MuiSelect-icon": {
                    display: "flex",
                  },
                  height: "2.8rem",
                }}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
              </Select>
            </MDBox>
            <MDBox
              mb={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              width="100%"
            >
              <MDTypography variant="h7" color="text" fontWeight="bold">
                Class Type
              </MDTypography>
              <Select
                id="statusSelectLabel"
                value={inputs.classType}
                name="classType"
                onChange={changeHandler}
                error={errors.classType}
                fullWidth
                sx={{
                  "& .MuiSelect-icon": {
                    display: "flex",
                  },
                  height: "2.8rem",
                }}
              >
                <MenuItem value={"In person"}>In person</MenuItem>
                <MenuItem value={"Online"}>Online</MenuItem>
              </Select>
            </MDBox>
          </MDBox>
        </MDBox>
        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddAttendanceDialog;
