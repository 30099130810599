import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutSuccess } from "../../redux/actions/actions";

function Logout() {
  const dipatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dipatch(logoutSuccess());

    navigate("/auth/login", { replace: true });
  }, []);
}

export default Logout;
