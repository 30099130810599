// Material Dashboard 2 React base styles
import colors from "../../base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "../../functions/pxToRem";
import boxShadow from "../../functions/boxShadow";

const { info } = colors;

const stepIcon = {
  styleOverrides: {
    root: {
      background: "#9fc9ff",
      fill: "#9fc9ff",
      stroke: "#9fc9ff",
      strokeWidth: pxToRem(10),
      width: pxToRem(13),
      height: pxToRem(13),
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",

      "&.Mui-active": {
        background: info.main,
        fill: info.main,
        stroke: info.main,
        borderColor: info.main,
        boxShadow: boxShadow([0, 0], [0, 2], info.main, 1),
      },

      "&.Mui-completed": {
        background: info.main,
        fill: info.main,
        stroke: info.main,
        borderColor: info.main,
        boxShadow: boxShadow([0, 0], [0, 2], info.main, 1),
      },
    },
  },
};

export default stepIcon;
