import axiosInstance from "./htttp.service";

class AttendanceApiService {
  axiosInstances = axiosInstance;

  // A list of All Student List
  addAttendance = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `attendance/students-attendace/`,
      payload,
      {
        headers,
      }
    );
  };

  // Most Recent Attendance
  getMostRecentAttendance = async (id) => {
    return await this.axiosInstances.get(
      `/attendance/most-recent-attendance/${id}`
    );
  };

  // Delete attendance
  deleteAttendance = async (id) => {
    return await this.axiosInstances.delete(
      `/attendance/students-attendace/${id}`
    );
  };

  // Add student attendance on hold
  addAttendanceOnHold = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `attendance/students-attendace-on-hold/`,
      payload,
      {
        headers,
      }
    );
  };

  // Most Attendance on Hold For date
  getAttendanceOnHold = async (date) => {
    return await this.axiosInstances.get(
      `/attendance/attendance-on-hold/?date=${date}`
    );
  };

  // Most Attendance on Hold For date
  getAllAttendanceOnHold = async () => {
    return await this.axiosInstances.get(
      `/attendance/attendance-on-hold/?validated=false`
    );
  };

  // Mark student attendance as verified
  markAttendaceAsVerified = async (id, payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.put(
      `/attendance/attendance-on-hold/${id}/`,
      payload,
      {
        headers,
      }
    );
  };

  // Reject Students Attendance
  rejectAttendanceOnHold = async (id) => {
    return await this.axiosInstances.delete(
      `/attendance/attendance-on-hold/${id}/`
    );
  };

  downloadCSV = async (startDate, endDate) => {
    return await this.axiosInstances.get(
      `/attendance/students-attendace/download_csv/?&date_after=${startDate}&date_before=${endDate}`
    );
  };

  downloadStudentCSV = async (id, startDate, endDate) => {
    return await this.axiosInstances.get(
      `/attendance/students-attendace/download_csv/?student=${id}&date_after=${startDate}&date_before=${endDate}`
    );
  };
}

export default new AttendanceApiService();
