import dateFormatter from "../DateFormatter";

const daysDifferenceCalculator = (inputDate) => {
  const convertedDate = dateFormatter(inputDate);
  const today = new Date();
  const passedInDate = new Date(convertedDate);

  const differenceInTime = today.getTime() - passedInDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  return differenceInDays;
};

export default daysDifferenceCalculator;
