import { useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import paymentAPIService from "services/paymentAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function AddPaymentDialog(props) {
  const { open, onClose, studentId, studentName } = props;
  const today = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-");

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    student: "",
    paidAmount: "",
    paymentDate: today,
    paymentMethod: "",
    note: "",
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  const [errors, setErrors] = useState({
    paidAmount: false,
    paymentDate: false,
    paymentMethod: false,
    note: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
    setProcessing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if everything has been input
    if (!inputs.paidAmount) {
      setErrors({ ...errors, paidAmount: true });
      return;
    } else {
      setErrors({ ...errors, paidAmount: false });
    }

    if (!inputs.paymentDate) {
      setErrors({ ...errors, paymentDate: true });
      return;
    } else {
      setErrors({ ...errors, paymentDate: false });
    }

    if (!inputs.paymentMethod) {
      setErrors({ ...errors, paymentMethod: true });
      return;
    } else {
      setErrors({ ...errors, paymentMethod: false });
    }

    // Now that everything is verified
    setProcessing(true);
    try {
      let payload = {
        student: studentId,
        paid_amount: inputs.paidAmount,
        payment_method: inputs.paymentMethod,
        payment_date: inputs.paymentDate,
        note: inputs.note,
      };

      const response = await paymentAPIService.addPayment(payload);
      setProcessing(false);
      setInputs({ ...initialState });
      onClose("success");
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to add the Payment Record.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Add New Payment
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>

      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Student
            </MDTypography>
            <MDInput
              value={studentName}
              name="studentName"
              readOnly
              fullWidth
            />
          </MDBox>
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Paid Amount
            </MDTypography>
            <MDInput
              type="number"
              value={inputs.paidAmount}
              name="paidAmount"
              onChange={changeHandler}
              error={errors.paidAmount}
              fullWidth
            />
          </MDBox>
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Payment Date
            </MDTypography>
            <MDInput
              type="date"
              value={inputs.paymentDate}
              name="paymentDate"
              onChange={changeHandler}
              error={errors.paymentDate}
              fullWidth
              InputProps={{
                inputProps: {
                  max: today,
                },
              }}
            />
          </MDBox>
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Payment Method
            </MDTypography>
            <Select
              id="statusSelectLabel"
              value={inputs.paymentMethod}
              name="paymentMethod"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.paymentMethod}
            >
              <MenuItem value={1}>Cash</MenuItem>
              <MenuItem value={2}>Zelle</MenuItem>
              <MenuItem value={3}>Venmo</MenuItem>
              <MenuItem value={4}>Paypal</MenuItem>
              <MenuItem value={5}>Check</MenuItem>
            </Select>
          </MDBox>
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Note
            </MDTypography>
            <MDInput
              type="text"
              value={inputs.note}
              name="note"
              onChange={changeHandler}
              error={errors.note}
              fullWidth
            />
          </MDBox>
        </MDBox>
        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddPaymentDialog;
