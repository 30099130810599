// Material Dashboard 2 React example components
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import {
  Icon,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import MDTypography from "components/MDTypography";

import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import Footer from "components/Footer";
import MDSnackbar from "components/MDSnackbar";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";

import corporateBG from "assets/images/corporate4.jpg";
import APIError from "components/ApiError";
import { useSelector } from "react-redux";

import phoneNumberMask from "components/Formatter/PhoneMask";
import corporateAPIService from "services/corporateAPI-service";
import AddCorporateCustomer from "./addCorporateCustomer";

function CorporateCustomers() {
  const userSelector = useSelector((state) => state.auth);
  const today = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-");
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [apiError, setApiError] = useState(false);

  const [name, setName] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selected, setSelected] = useState([]);

  const [editCustomer, setEditCustomer] = useState([]);
  const [editCustomerDialogOpen, setEditCustomerDialogOpen] = useState(false);
  const [addCustomerDialogOpen, setAddCustomerDialogOpen] = useState(false);

  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState("");

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
    setIsAllSelected(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
    setIsAllSelected(false);
  };

  const columns = [
    "Business Information",
    "Customer Information",
    "Address",
    "Note",
    "Action",
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setIsAllSelected(true);
      return;
    }
    setIsAllSelected(false);
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (!processing) {
      if (selected.length === rows.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
    }
  }, [selected]);

  useEffect(() => {
    setSelected([]);
    setIsAllSelected(false);

    fetchAllCorporateCustomersList();
  }, [page, rowsPerPage]);

  const fetchAllCorporateCustomersList = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const corporateCustomerList =
        await corporateAPIService.getCorporateCustomersList(
          rowsPerPage,
          page + 1,
          name,
          orderBy
        );
      setTotal(corporateCustomerList.data.count);
      setRows(corporateCustomerList.data.results);
      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDeleteConfirmation = (id) => {
    setDialogMessage(
      "Are you sure you want to remove this corporate customer record?"
    );
    setDeleteItem(id);
    setDialogOpen(true);
  };

  const handleDeleteALLConfirmation = () => {
    setDialogMessage(
      "Are you sure you want to remove these " +
        selected.length +
        " corporate customers record?"
    );
    setDeleteAllDialogOpen(true);
  };

  const handleDeleteALLClose = async (response) => {
    if (response === "No") {
      setDeleteAllDialogOpen(false);
    } else {
      setDeleteProcessing(true);
      let deleteCount = 0;
      let errorCount = 0;
      // loop through each ids in selected
      const deletePromises = selected.map(async (id) => {
        try {
          const deleteResponse =
            await corporateAPIService.deleteCorporateCustomer(id);
          deleteCount = deleteCount + 1;
        } catch (error) {
          errorCount = errorCount + 1;
        }
      });

      // Wait for all promises to be over

      const results = await Promise.all(deletePromises);

      let newPage = page;
      if (rows.length - (deleteCount + errorCount) <= 0) {
        newPage = newPage - 1;
        if (newPage < 0) {
          newPage = 0;
        }
      }

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage(
        deleteCount + " corporate customer records have been deleted."
      );
      openSnackBar();

      setSelected([]);
      setIsAllSelected(false);
      setDeleteProcessing(false);
      setPage(newPage);
      fetchAllCorporateCustomersList();
      setDeleteAllDialogOpen(false);
    }
  };

  const handleDeleteClose = async (response) => {
    if (response === "No") {
      setDeleteItem(null);
    } else {
      setDeleteProcessing(true);
      try {
        const deleteResponse =
          await corporateAPIService.deleteCorporateCustomer(deleteItem);
        setSnackBarColor("success");
        setSnackBarIcon("check");
        setSnackBarTitle("Success");
        setSnackBarMessage(deleteResponse.data.message);
        openSnackBar();

        setDeleteProcessing(false);
        setDeleteItem(null);
        // Handle Pagination and reload
        let newPage = page;
        if (rows.length === 1) {
          newPage = newPage - 1;
          if (newPage < 0) {
            newPage = 0;
          }
        }
        setPage(newPage);
        setSelected([]);
        setIsAllSelected(false);
        fetchAllCorporateCustomersList();
      } catch (error) {
        setSnackBarColor("error");
        setSnackBarIcon("warning");
        setSnackBarTitle("Error");
        setSnackBarMessage("Failed to remove the Corporate Customer Record.");
        openSnackBar();
        setDeleteProcessing(false);
      }
    }
    setDialogOpen(false);
  };

  const handleAddCustomer = () => {
    setAddCustomerDialogOpen(true);
  };

  const handleAddCustomerDialogClose = (response) => {
    if (response === "Close") {
      setAddCustomerDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Corporate customer has been added.");
      openSnackBar();
      fetchAllCorporateCustomersList();
      setAddCustomerDialogOpen(false);
    }
  };

  const handleEditCustomerDialogClose = (response) => {
    if (response === "Close") {
      setEditCustomerDialogOpen(false);
      setEditCustomer([]);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Corporate customer has been updated.");
      openSnackBar();
      fetchAllCorporateCustomersList();
      setEditCustomer([]);
      setEditCustomerDialogOpen(false);
    }
  };

  const handleEditCorporateCustomer = (editCustomer) => {
    setEditCustomer(editCustomer);
    setEditCustomerDialogOpen(true);
  };

  const renderEditCustomerDialog = (
    <AddCorporateCustomer
      open={editCustomerDialogOpen}
      onClose={handleEditCustomerDialogClose}
      existingDetails={editCustomer}
    />
  );

  const renderAddCustomerDialog = (
    <AddCorporateCustomer
      open={addCustomerDialogOpen}
      onClose={handleAddCustomerDialogClose}
    />
  );

  const renderDeleteALLDialog = (
    <DeleteConfirmationDialog
      open={deleteAllDialogOpen}
      onClose={handleDeleteALLClose}
      message={dialogMessage}
    />
  );

  const renderDeleteDialog = (
    <DeleteConfirmationDialog
      open={dialogOpen}
      onClose={handleDeleteClose}
      message={dialogMessage}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={10} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* Heading */}
              <MDBox
                mx={2}
                mt={-6}
                py={4}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="xxl"
                coloredShadow="info"
                height="100px"
                sx={{
                  backgroundImage: ({
                    functions: { rgba, linearGradient },
                    palette: { gradients },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.info.main, 0.1),
                      rgba(gradients.info.state, 0.1)
                    )}, url(${corporateBG})`,
                  backgroundSize: "cover",
                  backgroundPosition: "50%",
                  overflow: "hidden",
                }}
              >
                <MDTypography variant="h4" color="secondary">
                  Corporate Customers
                </MDTypography>
              </MDBox>

              {/* Add Button */}
              {Array.of("superadmin", "admin").includes(
                userSelector.userType
              ) && (
                <MDBox
                  mb={1}
                  mt={2}
                  pr={2}
                  display="flex"
                  justifyContent="end"
                  gap={2}
                  flexWrap="wrap"
                  onClick={handleAddCustomer}
                >
                  <MDButton variant="contained" color="dark" type="button">
                    + Add Customer
                  </MDButton>
                </MDBox>
              )}

              {/* Progress Bar */}
              {(processing || deleteProcessing) && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {/* // If More than one box is selected */}
              {selected.length > 0 && (
                <MDBox
                  mb={{ xs: 3, sm: 0 }}
                  mt={2}
                  px={3}
                  shadow="none"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDTypography
                    variant="h7"
                    color="secondary"
                    fontWeight="bold"
                  >
                    {selected.length} Entries Selected
                  </MDTypography>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "regular" }}
                    fontSize="medium"
                    color="error"
                    onClick={() => {
                      handleDeleteALLConfirmation();
                    }}
                  >
                    delete
                  </Icon>
                </MDBox>
              )}

              {/* No Data */}
              {!processing && !apiError && total === 0 && (
                <MDBox pt={3} px={4} pb={5}>
                  <MDTypography variant="h7" color="error">
                    No corporate customer found
                  </MDTypography>
                </MDBox>
              )}

              {/* Table */}
              <MDBox pt={3} px={1} pb={5}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table>
                    {/* Header Columns */}
                    <TableHead>
                      <TableRow>
                        {/* CheckBox */}
                        <TableCell>
                          <Checkbox
                            checked={isAllSelected}
                            color="primary"
                            size="medium"
                            onClick={handleSelectAllClick}
                          />
                        </TableCell>
                        {columns.map((column, i) => (
                          <TableCell key={i}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Body Contents */}
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow key={i}>
                          {/* CheckBox */}
                          <TableCell>
                            <Checkbox
                              checked={isSelected(row.id)}
                              color="primary"
                              size="medium"
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell>
                            <MDTypography
                              variant="h7"
                              fontWeight="bold"
                              color="info"
                            >
                              {row.business_name}
                            </MDTypography>

                            {row.business_phone && (
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="text"
                              >
                                <br />
                                {phoneNumberMask(row.business_phone)}
                              </MDTypography>
                            )}

                            {row.business_email && (
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="text"
                              >
                                <br />
                                {row.business_email}
                              </MDTypography>
                            )}
                          </TableCell>

                          <TableCell>
                            <MDTypography variant="h7" fontWeight="bold">
                              {row.customer_name}
                            </MDTypography>

                            {row.customer_phone && (
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="text"
                              >
                                <br />
                                {phoneNumberMask(row.customer_phone)}
                              </MDTypography>
                            )}

                            {row.customer_email && (
                              <MDTypography
                                variant="h7"
                                fontWeight="regular"
                                color="text"
                              >
                                <br />
                                {row.customer_email}
                              </MDTypography>
                            )}
                          </TableCell>

                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {row.address}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {row.note}
                            </MDTypography>
                          </TableCell>

                          <TableCell>
                            {Array.of("superadmin", "admin").includes(
                              userSelector.userType
                            ) && (
                              <Tooltip title="Edit Customer">
                                <Icon
                                  sx={{
                                    cursor: "pointer",
                                    fontWeight: "regular",
                                    margin: 1,
                                  }}
                                  fontSize="medium"
                                  color="warning"
                                  onClick={() => {
                                    handleEditCorporateCustomer(row);
                                  }}
                                >
                                  edit
                                </Icon>
                              </Tooltip>
                            )}

                            <Tooltip title="Delete Customer">
                              <Icon
                                sx={{
                                  cursor: "pointer",
                                  fontWeight: "regular",
                                  margin: 1,
                                }}
                                fontSize="medium"
                                color="error"
                                onClick={() => {
                                  handleDeleteConfirmation(row.id);
                                }}
                              >
                                delete_forever
                              </Icon>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </MDBox>

              {/* Api Error */}
              {!processing && apiError && <APIError />}
            </Card>
          </Grid>
        </Grid>
        {renderDeleteDialog}
        {renderDeleteALLDialog}
        {renderSnackBar}
        {renderAddCustomerDialog}
        {renderEditCustomerDialog}
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default CorporateCustomers;
