import axiosInstance from "./htttp.service";

class CorporateApiService {
  axiosInstances = axiosInstance;

  // A list of All Corporate Customers
  getCorporateCustomersList = async (pageSize, pageIndex, name, orderBy) => {
    return await this.axiosInstances.get(
      `/attendance/corporate-customers/?page_size=${pageSize}&page=${pageIndex}&search=${name}&ordering=${orderBy}`
    );
  };

  // Add Corporate Customer
  addCorporateCustomer = async (payload) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.post(
      `attendance/corporate-customers/`,
      payload,
      {
        headers,
      }
    );
  };

  // Update Corporate Customer
  updateCorporateCustomer = async (payload, id) => {
    const headers = {
      "Content-Type": "application/json",
    };

    return await this.axiosInstances.put(
      `attendance/corporate-customers/${id}`,
      payload,
      {
        headers,
      }
    );
  };

  // Delete
  deleteCorporateCustomer = async (id) => {
    return await this.axiosInstances.delete(
      `/attendance/corporate-customers/${id}`
    );
  };
}

export default new CorporateApiService();
