export const Days = [
  { id: "0", name: "Sunday" },
  { id: "1", name: "Monday" },
  { id: "2", name: "Tuesday" },
  { id: "3", name: "Wednesday" },
  { id: "4", name: "Thursday" },
  { id: "5", name: "Friday" },
  { id: "6", name: "Saturday" },
];

export const DaysList = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const DaysNumberMap = new Map()
  .set("Monday", 1)
  .set("Tuesday", 2)
  .set("Wednesday", 3)
  .set("Thursday", 4)
  .set("Friday", 5)
  .set("Saturday", 6);
