// Material Dashboard 2 React example components
import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import {
  CircularProgress,
  Icon,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";

import AddAttendanceDialog from "appStack/students/scheduleDetails/addAttendance";
import DeleteConfirmationDialog from "components/Dialog/DeleteDialog";
import Footer from "components/Footer";
import MDSnackbar from "components/MDSnackbar";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DashboardLayout from "layouts/LayoutContainers/DashboardLayout";

import scheduleBG from "assets/images/whiteBG3.jpg";
import APIError from "components/ApiError";
import dateFormatter from "components/Formatter/DateFormatter";
import defaultconvertTimeTo12HourFormat from "components/Formatter/HourMinuteFormatter";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import attendanceAPIService from "services/attendanceAPI-service";
import studentAPIService from "services/studentAPI-service";

import csvIcon from "assets/icons/csvIcon.svg";
import { saveAs } from "file-saver";
import dayExtractor from "components/Formatter/DayExtractor";

function Attendance() {
  const userSelector = useSelector((state) => state.auth);
  const today = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-");
  const [rows, setRows] = useState([]);
  const [total, setTotal] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [downloadProcessing, setDownloadProcessing] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selected, setSelected] = useState([]);

  const [deleteAllDialogOpen, setDeleteAllDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [deleteItem, setDeleteItem] = useState("");
  const [addAttendanceDialogOpen, setAddAttendanceDialogOpen] = useState(false);

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [errors, setErrors] = useState({
    startDate: false,
    endDate: false,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelected([]);
    setIsAllSelected(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setSelected([]);
    setIsAllSelected(false);
  };

  const columns = [
    "Student",
    "Status",
    "Date",
    "Start",
    "End",
    "Type",
    "# Classes",
    "Cost",
    "Action",
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setIsAllSelected(true);
      return;
    }
    setIsAllSelected(false);
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (!processing) {
      if (selected.length === rows.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
    }
  }, [selected]);

  useEffect(() => {
    setSelected([]);
    setIsAllSelected(false);

    fetchStudentAttendanceData();
  }, [page, rowsPerPage, startDate, endDate]);

  const fetchStudentAttendanceData = async () => {
    setProcessing(true);
    setApiError(false);
    try {
      const studentsAttendanceList =
        await studentAPIService.getAllStudentsAttendance(
          startDate,
          endDate,
          rowsPerPage,
          page + 1
        );
      setTotal(studentsAttendanceList.data.count);
      setRows(studentsAttendanceList.data.results);
      setProcessing(false);
      setApiError(false);
    } catch (error) {
      setApiError(true);
      setProcessing(false);
    }
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleDeleteConfirmation = (id) => {
    setDialogMessage("Are you sure you want to remove this attendace record?");
    setDeleteItem(id);
    setDialogOpen(true);
  };

  const handleDeleteALLConfirmation = () => {
    setDialogMessage(
      "Are you sure you want to remove these " +
        selected.length +
        " attendance record?"
    );
    setDeleteAllDialogOpen(true);
  };

  const handleDeleteALLClose = async (response) => {
    if (response === "No") {
      setDeleteAllDialogOpen(false);
    } else {
      setDeleteProcessing(true);
      let deleteCount = 0;
      let errorCount = 0;
      // loop through each ids in selected
      const deletePromises = selected.map(async (id) => {
        try {
          const deleteResponse = await attendanceAPIService.deleteAttendance(
            id
          );
          deleteCount = deleteCount + 1;
        } catch (error) {
          errorCount = errorCount + 1;
        }
      });

      // Wait for all promises to be over

      const results = await Promise.all(deletePromises);

      let newPage = page;
      if (rows.length - (deleteCount + errorCount) <= 0) {
        newPage = newPage - 1;
        if (newPage < 0) {
          newPage = 0;
        }
      }

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage(
        deleteCount + " attendance records have been deleted."
      );
      openSnackBar();

      setSelected([]);
      setIsAllSelected(false);
      setDeleteProcessing(false);
      setPage(newPage);
      fetchStudentAttendanceData();
      setDeleteAllDialogOpen(false);
    }
  };

  const handleDeleteClose = async (response) => {
    if (response === "No") {
      setDeleteItem(null);
    } else {
      setDeleteProcessing(true);
      try {
        const deleteResponse = await attendanceAPIService.deleteAttendance(
          deleteItem
        );
        setSnackBarColor("success");
        setSnackBarIcon("check");
        setSnackBarTitle("Success");
        setSnackBarMessage(deleteResponse.data.message);
        openSnackBar();

        setDeleteProcessing(false);
        setDeleteItem(null);
        // Handle Pagination and reload
        let newPage = page;
        if (rows.length === 1) {
          newPage = newPage - 1;
          if (newPage < 0) {
            newPage = 0;
          }
        }
        setPage(newPage);
        setSelected([]);
        setIsAllSelected(false);
        fetchStudentAttendanceData();
      } catch (error) {
        setSnackBarColor("error");
        setSnackBarIcon("warning");
        setSnackBarTitle("Error");
        setSnackBarMessage("Failed to remove the Attendance Record.");
        openSnackBar();
        setDeleteProcessing(false);
      }
    }
    setDialogOpen(false);
  };

  const handleAddAttendanceClose = (response) => {
    if (response === "Close") {
      setAddAttendanceDialogOpen(false);
    } else {
      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Attendance history list has been updated.");
      openSnackBar();
    }
    setAddAttendanceDialogOpen(false);
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setErrors({ startDate: false, endDate: false });
    setSelected([]);
    setIsAllSelected(false);
  };

  const dateChangeHandler = (e) => {
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);
    } else {
      setEndDate(e.target.value);
    }
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!startDate) {
      setErrors({ ...errors, startDate: true });
      return;
    } else {
      setErrors({ ...errors, startDate: false });
    }

    if (!endDate) {
      setErrors({ ...errors, endDate: true });
      return;
    } else {
      setErrors({ ...errors, endDate: false });
    }

    // Now everything looks good, send the start date and end date
    fetchStudentAttendanceData();
  };

  const downloadCSV = async () => {
    setDownloadProcessing(true);
    try {
      const report = await attendanceAPIService.downloadCSV(startDate, endDate);
      const blob = new Blob([report.data], { type: "text/csv" });

      // Now open save as to save this csv file
      saveAs(blob, "attendanceReport.csv");
      setDownloadProcessing(false);

      setSnackBarColor("success");
      setSnackBarIcon("check");
      setSnackBarTitle("Success");
      setSnackBarMessage("Attendance report has been downloaded.");
      openSnackBar();
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to download the Attendance Report.");
      openSnackBar();
      setDownloadProcessing(false);
    }
  };

  const renderDeleteALLDialog = (
    <DeleteConfirmationDialog
      open={deleteAllDialogOpen}
      onClose={handleDeleteALLClose}
      message={dialogMessage}
    />
  );

  const renderDeleteDialog = (
    <DeleteConfirmationDialog
      open={dialogOpen}
      onClose={handleDeleteClose}
      message={dialogMessage}
    />
  );

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  const renderAddAttendanceDialog = (
    <AddAttendanceDialog
      open={addAttendanceDialogOpen}
      onClose={handleAddAttendanceClose}
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Date Filtering & Download button */}
      <MDBox
        pt={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        gap={2}
        flexWrap="wrap"
      >
        {/* Date Filtering */}
        <MDBox display="flex" alignItems="center" gap={2} flexWrap="wrap">
          <MDTypography variant="h7">From</MDTypography>
          <MDInput
            type="date"
            value={startDate}
            name="startDate"
            InputProps={{
              inputProps: {
                max: today,
              },
            }}
            onChange={dateChangeHandler}
            error={errors.startDate}
          />
          <MDTypography variant="h7">To</MDTypography>
          <MDInput
            type="date"
            value={endDate}
            name="endDate"
            InputProps={{
              inputProps: {
                min: startDate,
                max: today,
              },
            }}
            onChange={dateChangeHandler}
            error={errors.endDate}
          />

          <MDButton size="medium" color="success" onClick={handleSearch}>
            Search
          </MDButton>

          <MDButton
            size="medium"
            color="error"
            iconOnly
            circular
            onClick={handleClear}
          >
            <Icon> close</Icon>
          </MDButton>
        </MDBox>

        {/* Show download button only when admin or superadmin */}
        {Array.of("admin", "superadmin").includes(userSelector.userType) && (
          <MDBox display="flex" justifyContent="right">
            <MDButton
              color="info"
              disabled={downloadProcessing}
              onClick={downloadCSV}
            >
              <img
                src={csvIcon}
                name="csvIcons"
                height={20}
                width={25}
                style={{ marginRight: "5px" }}
              />
              Download Attendance Report
              {downloadProcessing && (
                <CircularProgress
                  size={14}
                  sx={{ marginLeft: 1 }}
                  color="warning"
                />
              )}
            </MDButton>
          </MDBox>
        )}
      </MDBox>

      <MDBox pt={10} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              {/* Heading */}
              <MDBox
                mx={2}
                mt={-6}
                py={4}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="xxl"
                coloredShadow="info"
                height="100px"
                sx={{
                  backgroundImage: ({
                    functions: { rgba, linearGradient },
                    palette: { gradients },
                  }) =>
                    `${linearGradient(
                      rgba(gradients.info.main, 0.7),
                      rgba(gradients.info.state, 0.1)
                    )}, url(${scheduleBG})`,
                  backgroundSize: "cover",
                  backgroundPosition: "50%",
                  overflow: "hidden",
                }}
              >
                <MDTypography variant="h4" color="dark">
                  Attendance History
                </MDTypography>
              </MDBox>

              {/* Progress Bar */}
              {(processing || deleteProcessing) && (
                <MDBox pt={2} px={2}>
                  <LinearProgress
                    color="info"
                    variant="indeterminate"
                    sx={{ overflow: "hidden" }}
                  />
                </MDBox>
              )}

              {/* // If More than one box is selected */}
              {selected.length > 0 && (
                <MDBox
                  mb={{ xs: 3, sm: 0 }}
                  mt={2}
                  px={3}
                  shadow="none"
                  display="flex"
                  justifyContent="space-between"
                >
                  <MDTypography
                    variant="h7"
                    color="secondary"
                    fontWeight="bold"
                  >
                    {selected.length} Entries Selected
                  </MDTypography>
                  <Icon
                    sx={{ cursor: "pointer", fontWeight: "regular" }}
                    fontSize="medium"
                    color="error"
                    onClick={() => {
                      handleDeleteALLConfirmation();
                    }}
                  >
                    delete
                  </Icon>
                </MDBox>
              )}

              {/* No Data */}
              {!processing && !apiError && total === 0 && (
                <MDBox pt={3} px={4} pb={5}>
                  <MDTypography variant="h7" color="error">
                    No attendace history found
                  </MDTypography>
                </MDBox>
              )}

              {/* Table */}
              <MDBox pt={3} px={1} pb={5}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table>
                    {/* Header Columns */}
                    <TableHead>
                      <TableRow>
                        {/* CheckBox */}
                        <TableCell>
                          <Checkbox
                            checked={isAllSelected}
                            color="primary"
                            size="medium"
                            onClick={handleSelectAllClick}
                          />
                        </TableCell>
                        {columns.map((column, i) => (
                          <TableCell key={i}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {/* Body Contents */}
                    <TableBody>
                      {rows.map((row, i) => (
                        <TableRow key={i}>
                          {/* CheckBox */}
                          <TableCell>
                            <Checkbox
                              checked={isSelected(row.id)}
                              color="primary"
                              size="medium"
                              onClick={(event) => handleClick(event, row.id)}
                            />
                          </TableCell>
                          <TableCell>
                            <Link to={`/students/${row.student.id}`}>
                              <MDTypography
                                variant="h7"
                                fontWeight="bold"
                                color="info"
                              >
                                {row.student.first_name}&nbsp;
                                {row.student.last_name}
                              </MDTypography>
                            </Link>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              <MDBadge
                                badgeContent={
                                  row?.present ? "Present" : "Absent"
                                }
                                color={row?.present ? "success" : "dark"}
                                variant="gradient"
                                size="sm"
                              />
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {dayExtractor(row.date)},{" "}
                              {dateFormatter(row.date)}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {defaultconvertTimeTo12HourFormat(row.start_time)}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {defaultconvertTimeTo12HourFormat(row.end_time)}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="bold">
                              {row.Class_type}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="regular">
                              {row.Number_of_classes}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <MDTypography variant="h7" fontWeight="bold">
                              $ {row.cost_of_this_attendance}
                            </MDTypography>

                            <MDTypography variant="h8" fontWeight="regular">
                              <br />
                              Recorded On: {dateFormatter(row.last_updated)}
                            </MDTypography>
                          </TableCell>
                          <TableCell>
                            <Icon
                              sx={{ cursor: "pointer", fontWeight: "regular" }}
                              fontSize="medium"
                              color="error"
                              onClick={() => {
                                handleDeleteConfirmation(row.id);
                              }}
                            >
                              delete_forever
                            </Icon>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  showFirstButton
                  showLastButton
                />
              </MDBox>

              {/* Api Error */}
              {!processing && apiError && <APIError />}
            </Card>
          </Grid>
        </Grid>
        {renderDeleteDialog}
        {renderDeleteALLDialog}
        {renderSnackBar}
        {renderAddAttendanceDialog}
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Attendance;
