import { useEffect, useState } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  MenuItem,
  Select,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { forwardRef } from "react";
import studentAPIService from "services/studentAPI-service";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function AddStudentConfigDetails(props) {
  const { open, onClose, studentId, studentName, existingDetails } = props;
  const today = new Date()
    .toLocaleDateString("en-GB")
    .split("/")
    .reverse()
    .join("-");

  const [processing, setProcessing] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("success");
  const [snackBarIcon, setSnackBarIcon] = useState("");
  const [snackBarTitle, setSnackBarTitle] = useState("");
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const openSnackBar = () => setSnackBarOpen(true);
  const closeSnackBar = () => setSnackBarOpen(false);

  const initialState = {
    enter_art_contests: false,
    exhibit_artwork: false,
    receive_emails: false,
    receive_text: false,
    sell_artwork_permission: false,
    payment_method: 1,
  };

  const [inputs, setInputs] = useState({
    ...initialState,
  });

  useEffect(() => {
    if (existingDetails && existingDetails.length > 0) {
      setInputs({
        enter_art_contests: existingDetails.at(0).enter_art_contests,
        exhibit_artwork: existingDetails.at(0).exhibit_artwork,
        receive_emails: existingDetails.at(0).receive_emails,
        receive_text: existingDetails.at(0).receive_text,
        sell_artwork_permission: existingDetails.at(0).sell_artwork_permission,
        payment_method: existingDetails.at(0).payment_method,
      });
    }
  }, [existingDetails]);

  const [errors, setErrors] = useState({
    enter_art_contests: false,
    exhibit_artwork: false,
    receive_emails: false,
    receive_text: false,
    sell_artwork_permission: false,
    payment_method: false,
  });

  const handleCancel = () => {
    onClose("Close");
    setInputs({ ...initialState });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Now that everything is ok
    setProcessing(true);

    try {
      let payload = {
        student: studentId,
        enter_art_contests: inputs.enter_art_contests,
        exhibit_artwork: inputs.exhibit_artwork,
        receive_emails: inputs.receive_emails,
        receive_text: inputs.receive_text,
        sell_artwork_permission: inputs.sell_artwork_permission,
        payment_method: inputs.payment_method,
      };

      if (existingDetails && existingDetails.length > 0) {
        const response = await studentAPIService.updateConfigDetails(
          existingDetails.at(0).id,
          payload
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      } else {
        const response = await studentAPIService.addNewStudentConfigDetails(
          payload
        );
        setProcessing(false);
        setInputs({ ...initialState });
        onClose("success");
      }
    } catch (error) {
      setSnackBarColor("error");
      setSnackBarIcon("warning");
      setSnackBarTitle("Error");
      setSnackBarMessage("Failed to edit the Student Configuration Details.");
      openSnackBar();
      setProcessing(false);
    }
  };

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const renderSnackBar = (
    <MDSnackbar
      color={snackBarColor}
      icon={snackBarIcon}
      title={snackBarTitle}
      content={snackBarMessage}
      dateTime=""
      open={snackBarOpen}
      onClose={closeSnackBar}
      close={closeSnackBar}
      bgWhite
    />
  );

  return (
    <Dialog
      TransitionComponent={Transition}
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <MDTypography color="info" fontWeight="bold">
          Configuration Details
        </MDTypography>
        <MDButton iconOnly onClick={handleCancel} disabled={processing}>
          <Icon fontSize="large">cancel</Icon>
        </MDButton>
      </DialogTitle>

      <DialogContent>
        <MDBox
          variant="contained"
          component="form"
          role="form"
          method="POST"
          onSubmit={handleSubmit}
          mt={2}
        >
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Student
            </MDTypography>
            <MDInput
              value={studentName}
              name="studentName"
              readOnly
              fullWidth
            />
          </MDBox>

          {/*  Enter Art Contests */}
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Enter Art Contests
            </MDTypography>
            <Select
              id="enter_art_contests_selector"
              value={inputs.enter_art_contests}
              name="enter_art_contests"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.enter_art_contests}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </MDBox>

          {/*  Exhibit Artwork */}
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Exhibit Artwork
            </MDTypography>
            <Select
              id="exhibit_artwork_selector"
              value={inputs.exhibit_artwork}
              name="exhibit_artwork"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.exhibit_artwork}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </MDBox>

          {/* Receive Emails */}
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Receive Emails
            </MDTypography>
            <Select
              id="receive_emails_selector"
              value={inputs.receive_emails}
              name="receive_emails"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.receive_emails}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </MDBox>

          {/* Receive Text */}
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Receive Text
            </MDTypography>
            <Select
              id="receive_text"
              value={inputs.receive_text}
              name="receive_text"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.receive_text}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </MDBox>

          {/* sell artwork permission */}
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Sell Artwork
            </MDTypography>
            <Select
              id="sell_artwork_permission"
              value={inputs.sell_artwork_permission}
              name="sell_artwork_permission"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.sell_artwork_permission}
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </MDBox>

          {/* Payment Method */}
          <MDBox
            mb={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            width="100%"
          >
            <MDTypography variant="h7" color="text" fontWeight="bold">
              Payment Method
            </MDTypography>
            <Select
              id="payment_method"
              value={inputs.payment_method}
              name="payment_method"
              onChange={changeHandler}
              fullWidth
              sx={{
                "& .MuiSelect-icon": {
                  display: "flex",
                },
                height: "2.8rem",
              }}
              error={errors.payment_method}
            >
              <MenuItem value={1}>Cash</MenuItem>
              <MenuItem value={2}>Zelle</MenuItem>
              <MenuItem value={3}>Venmo</MenuItem>
              <MenuItem value={4}>Paypal</MenuItem>
              <MenuItem value={5}>Check</MenuItem>
            </Select>
          </MDBox>
        </MDBox>
        <DialogActions>
          {/* Form Here */}
          <MDButton
            variant="contained"
            color="light"
            onClick={handleCancel}
            disabled={processing}
          >
            No
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            onClick={handleSubmit}
            disabled={processing}
          >
            Submit{" "}
            {processing && (
              <CircularProgress
                size={14}
                sx={{ marginLeft: 1 }}
                color="warning"
              />
            )}
          </MDButton>
        </DialogActions>
      </DialogContent>
      {renderSnackBar}
    </Dialog>
  );
}

export default AddStudentConfigDetails;
